// User Creation Flow images
import CodeCreateDefaultPt from '../assets/icons/code-create/default-pt.svg'
import CodeCreateWebsitePt from '../assets/icons/code-create/website-pt.svg'
import CodeCreateWebsiteEs from '../assets/icons/code-create/website-es.svg'
import CodeCreateDefaultDe from '../assets/icons/code-create/default-de.svg'
import CodeCreateDefaultEs from '../assets/icons/code-create/default-es.svg'
import CodeCreateWebsiteDe from '../assets/icons/code-create/website-de.svg'
import CodeCreateImagePt from '../assets/icons/code-create/image-pt.svg'
import CodeCreateImageDe from '../assets/icons/code-create/image-de.svg'
import CodeCreateImageEs from '../assets/icons/code-create/image-es.svg'
import CodeCreateVideoPt from '../assets/icons/code-create/video-pt.svg'
import CodeCreateVideoDe from '../assets/icons/code-create/video-de.svg'
import CodeCreateVideoEs from '../assets/icons/code-create/video-es.svg'
import CodeCreateDefault from '../assets/icons/code-create/default.svg'
import CodeCreateWebsite from '../assets/icons/code-create/website.svg'
import CodeCreateTextPt from '../assets/icons/code-create/text-pt.svg'
import CodeCreateTextDe from '../assets/icons/code-create/text-de.svg'
import CodeCreateTextEs from '../assets/icons/code-create/text-es.svg'
import CodeCreateWifiPt from '../assets/icons/code-create/wifi-pt.svg'
import CodeCreateWifiDe from '../assets/icons/code-create/wifi-de.svg'
import CodeCreateWifiEs from '../assets/icons/code-create/wifi-es.svg'
import CodeCreatePdfPt from '../assets/icons/code-create/pdf-pt.svg'
import CodeCreatePdfDe from '../assets/icons/code-create/pdf-de.svg'
import CodeCreatePdfEs from '../assets/icons/code-create/pdf-es.svg'
import CodeCreateMp3Pt from '../assets/icons/code-create/mp3-pt.svg'
import CodeCreateMp3De from '../assets/icons/code-create/mp3-de.svg'
import CodeCreateMp3Es from '../assets/icons/code-create/mp3-es.svg'
import CodeCreateImage from '../assets/icons/code-create/image.svg'
import CodeCreateVideo from '../assets/icons/code-create/video.svg'
import CodeCreateText from '../assets/icons/code-create/text.svg'
import CodeCreateWifi from '../assets/icons/code-create/wifi.svg'
import CodeCreateMp3 from '../assets/icons/code-create/mp3.svg'
import CodeCreatePdf from '../assets/icons/code-create/pdf.svg'

// Onboarding Modal Welcome images
import OnboardingWelcomeMobilePt from '../assets/icons/onboarding/welcome-mobile-pt.svg'
import OnboardingWelcomeMobileDe from '../assets/icons/onboarding/welcome-mobile-de.svg'
import OnboardingWelcomeMobileEs from '../assets/icons/onboarding/welcome-mobile-es.svg'
import OnboardingWelcomeMobile from '../assets/icons/onboarding/welcome-mobile.svg'
import OnboardingWelcomePt from '../assets/icons/onboarding/welcome-pt.svg'
import OnboardingWelcomeDe from '../assets/icons/onboarding/welcome-de.svg'
import OnboardingWelcomeEs from '../assets/icons/onboarding/welcome-es.svg'
import OnboardingTrendPt from '../assets/icons/onboarding/trend-pt.svg'
import OnboardingTrendDe from '../assets/icons/onboarding/trend-de.svg'
import OnboardingTrendEs from '../assets/icons/onboarding/trend-es.svg'
import OnboardingWelcome from '../assets/icons/onboarding/welcome.svg'
import OnboardingTrend from '../assets/icons/onboarding/trend.svg'

// Onboarding Modal Second Step images
import OnboardingTrackingMobilePt from '../assets/icons/onboarding/tracking-mobile-pt.svg'
import OnboardingTrackingMobileDe from '../assets/icons/onboarding/tracking-mobile-de.svg'
import OnboardingTrackingMobileEs from '../assets/icons/onboarding/tracking-mobile-es.svg'
import OnboardingTrackingMobile from '../assets/icons/onboarding/tracking-mobile.svg'
import OnboardingGreyCodePt from '../assets/icons/onboarding/grey-code-pt.svg'
import OnboardingGreyCodeDe from '../assets/icons/onboarding/grey-code-de.svg'
import OnboardingGreyCodeEs from '../assets/icons/onboarding/grey-code-es.svg'
import OnboardingTrackingPt from '../assets/icons/onboarding/tracking-pt.svg'
import OnboardingTrackingDe from '../assets/icons/onboarding/tracking-de.svg'
import OnboardingTrackingEs from '../assets/icons/onboarding/tracking-es.svg'
import OnboardingGreyCode from '../assets/icons/onboarding/grey-code.svg'
import OnboardingTracking from '../assets/icons/onboarding/tracking.svg'

// Onboarding Carousel Preview images
import OnboardingImagePreviewPt from '../assets/icons/onboarding/image-preview-pt.svg'
import OnboardingImagePreviewEs from '../assets/icons/onboarding/image-preview-es.svg'
import OnboardingVideoPreviewPt from '../assets/icons/onboarding/video-preview-pt.svg'
import OnboardingVideoPreviewEs from '../assets/icons/onboarding/video-preview-es.svg'
import OnboardingImagePreviewDe from '../assets/icons/onboarding/image-preview-de.svg'
import OnboardingVideoPreviewDe from '../assets/icons/onboarding/video-preview-de.svg'
import OnboardingWifiPreviewDe from '../assets/icons/onboarding/wifi-preview-de.svg'
import OnboardingTextPreviewDe from '../assets/icons/onboarding/text-preview-de.svg'
import OnboardingTextPreviewEs from '../assets/icons/onboarding/text-preview-es.svg'
import OnboardingWifiPreviewPt from '../assets/icons/onboarding/wifi-preview-pt.svg'
import OnboardingWifiPreviewEs from '../assets/icons/onboarding/wifi-preview-es.svg'
import OnboardingTextPreviewPt from '../assets/icons/onboarding/text-preview-pt.svg'
import OnboardingPdfPreviewDe from '../assets/icons/onboarding/pdf-preview-de.svg'
import OnboardingMp3PreviewDe from '../assets/icons/onboarding/mp3-preview-de.svg'
import OnboardingMp3PreviewEs from '../assets/icons/onboarding/mp3-preview-es.svg'
import OnboardingPdfPreviewPt from '../assets/icons/onboarding/pdf-preview-pt.svg'
import OnboardingPdfPreviewEs from '../assets/icons/onboarding/pdf-preview-es.svg'
import OnboardingMp3PreviewPt from '../assets/icons/onboarding/mp3-preview-pt.svg'
import OnboardingVideoPreview from '../assets/icons/onboarding/video-preview.svg'
import OnboardingImagePreview from '../assets/icons/onboarding/image-preview.svg'
import OnboardingTextPreview from '../assets/icons/onboarding/text-preview.svg'
import OnboardingWifiPreview from '../assets/icons/onboarding/wifi-preview.svg'
import OnboardingPdfPreview from '../assets/icons/onboarding/pdf-preview.svg'
import OnboardingMp3Preview from '../assets/icons/onboarding/mp3-preview.svg'

// Stripe Powered by image
import PoweredByStripePt from '../assets/images/powered-by-stripe-pt.png'
import PoweredByStripe from '../assets/icons/powered-by-stripe.png'

// Landing page introducing section images
import homeMobileIntroducingPtPng from '../assets/images/home-mobile-introducing-pt.png'
import homeMobileIntroducingDePng from '../assets/images/home-mobile-introducing-de.png'
import homeMobileIntroducingEsSvg from '../assets/images/home-mobile-introducing-es.png'
import homeMobileIntroducingSvg from '../assets/images/home-mobile-introducing.png'
import homeNotificationDePng from '../assets/images/home-notification-de.png'
import homeNotificationPtPng from '../assets/images/home-notification-pt.png'
import homeNotificationEsSvg from '../assets/images/home-notification-es.png'
import homeNotificationSvg from '../assets/images/home-notification.png'
import homeDesktopPtPng from '../assets/images/home-desktop-pt.png'
import homeDesktopDePng from '../assets/images/home-desktop-de.png'
import homeDesktopEsPng from '../assets/images/home-desktop-es.png'
import homeDesktopSvg from '../assets/images/home-desktop.png'

// Landing page discover section images
import homeVideoPreviewPtPng from '../assets/images/home-video-preview-pt.png'
import homeImagePreviewPtPng from '../assets/images/home-image-preview-pt.png'
import homeImagePreviewEsSvg from '../assets/images/home-image-preview-es.png'
import homeVideoPreviewDePng from '../assets/images/home-video-preview-de.png'
import homeVideoPreviewEsPng from '../assets/images/home-video-preview-es.png'
import homeImagePreviewDePng from '../assets/images/home-image-preview-de.png'
import homeTextPreviewPtPng from '../assets/images/home-text-preview-pt.png'
import homeTextPreviewEsPng from '../assets/images/home-text-preview-es.png'
import homeWifiPreviewPtPng from '../assets/images/home-wifi-preview-pt.png'
import homeWifiPreviewEsPng from '../assets/images/home-wifi-preview-es.png'
import homeTextPreviewDePng from '../assets/images/home-text-preview-de.png'
import homeWifiPreviewDePng from '../assets/images/home-wifi-preview-de.png'
import homeUrlPreviewDePng from '../assets/images/home-url-preview-de.png'
import homeUrlPreviewPtPng from '../assets/images/home-url-preview-pt.png'
import homeUrlPreviewEsPng from '../assets/images/home-url-preview-es.png'
import homePdfPreviewPtPng from '../assets/images/home-pdf-preview-pt.png'
import homePdfPreviewEsPng from '../assets/images/home-pdf-preview-es.png'
import homeMp3PreviewPtPng from '../assets/images/home-mp3-preview-pt.png'
import homeMp3PreviewEsPng from '../assets/images/home-mp3-preview-es.png'
import homePdfPreviewDePng from '../assets/images/home-pdf-preview-de.png'
import homeMp3PreviewDePng from '../assets/images/home-mp3-preview-de.png'
import homeImagePreviewSvg from '../assets/images/home-image-preview.png'
import homeVideoPreviewSvg from '../assets/images/home-video-preview.png'
import homeTextPreviewSvg from '../assets/images/home-text-preview.svg'
import homeWifiPreviewSvg from '../assets/images/home-wifi-preview.png'
import homeUrlPreviewSvg from '../assets/images/home-url-preview.png'
import homePdfPreviewSvg from '../assets/images/home-pdf-preview.png'
import homeMp3PreviewSvg from '../assets/images/home-mp3-preview.png'

// Landing page discover section images translations based on the language
export const LANDING_PAGE_DISCOVER_SECTION_IMAGES_TRANSLATIONS = {
    'en': {
        'url': homeUrlPreviewSvg,
        'text': homeTextPreviewSvg,
        'wi-fi': homeWifiPreviewSvg,
        'pdf': homePdfPreviewSvg,
        'video': homeVideoPreviewSvg,
        'mp3': homeMp3PreviewSvg,
        'image': homeImagePreviewSvg,
    },
    'pt': {
        'url': homeUrlPreviewPtPng,
        'text': homeTextPreviewPtPng,
        'wi-fi': homeWifiPreviewPtPng,
        'pdf': homePdfPreviewPtPng,
        'video': homeVideoPreviewPtPng,
        'mp3': homeMp3PreviewPtPng,
        'image': homeImagePreviewPtPng,
    },
    'de': {
        'url': homeUrlPreviewDePng,
        'text': homeTextPreviewDePng,
        'wi-fi': homeWifiPreviewDePng,
        'pdf': homePdfPreviewDePng,
        'video': homeVideoPreviewDePng,
        'mp3': homeMp3PreviewDePng,
        'image': homeImagePreviewDePng,
    },
    'es': {
        'url': homeUrlPreviewEsPng,
        'text': homeTextPreviewEsPng,
        'wi-fi': homeWifiPreviewEsPng,
        'pdf': homePdfPreviewEsPng,
        'video': homeVideoPreviewEsPng,
        'mp3': homeMp3PreviewEsPng,
        'image': homeImagePreviewEsSvg,
    }
}

// Landing page introducing section images translations based on the language
export const LANDING_PAGE_INTRODUCING_SECTION_IMAGES_TRANSLATIONS = {
    'en': {
        'mobile': homeMobileIntroducingSvg,
        'desktop': homeDesktopSvg,
        'notification': homeNotificationSvg,
    },
    'pt': {
        'mobile': homeMobileIntroducingPtPng,
        'desktop': homeDesktopPtPng,
        'notification': homeNotificationPtPng,
    },
    'de': {
        'mobile': homeMobileIntroducingDePng,
        'desktop': homeDesktopDePng,
        'notification': homeNotificationDePng,
    },
    'es': {
        'mobile': homeMobileIntroducingEsSvg,
        'desktop': homeDesktopEsPng,
        'notification': homeNotificationEsSvg,
    }
}

// Powered by Stripe images translations based on the language
export const POWERED_BY_STRIPE_IMAGES_TRANSLATIONS = {
    'en': PoweredByStripe,
    'pt': PoweredByStripePt,
    'de': PoweredByStripe,
    'es': PoweredByStripe,
}

// User Creation Flow images translation based on the language
export const USER_CREATE_CODE_IMAGES_TRANSLATIONS = {
    'en': {
        'URL': CodeCreateWebsite,
        'Text': CodeCreateText,
        'Wi-Fi': CodeCreateWifi,
        'Image': CodeCreateImage,
        'PDF': CodeCreatePdf,
        'Video': CodeCreateVideo,
        'MP3': CodeCreateMp3,
        'DEFAULT': CodeCreateDefault,
    },
    'pt': {
        'URL': CodeCreateWebsitePt,
        'Text': CodeCreateTextPt,
        'Wi-Fi': CodeCreateWifiPt,
        'Image': CodeCreateImagePt,
        'PDF': CodeCreatePdfPt,
        'Video': CodeCreateVideoPt,
        'MP3': CodeCreateMp3Pt,
        'DEFAULT': CodeCreateDefaultPt,
    },
    'de': {
        'URL': CodeCreateWebsiteDe,
        'Text': CodeCreateTextDe,
        'Wi-Fi': CodeCreateWifiDe,
        'Image': CodeCreateImageDe,
        'PDF': CodeCreatePdfDe,
        'Video': CodeCreateVideoDe,
        'MP3': CodeCreateMp3De,
        'DEFAULT': CodeCreateDefaultDe,
    },
    'es': {
        'URL': CodeCreateWebsiteEs,
        'Text': CodeCreateTextEs,
        'Wi-Fi': CodeCreateWifiEs,
        'Image': CodeCreateImageEs,
        'PDF': CodeCreatePdfEs,
        'Video': CodeCreateVideoEs,
        'MP3': CodeCreateMp3Es,
        'DEFAULT': CodeCreateDefaultEs,
    }
}

// Onboarding modal welcome images translations based on the language
export const ONBOARDING_MODAL_WELCOME_IMAGES_TRANSLATIONS = {
    'en': {
        'mobile': OnboardingWelcomeMobile,
        'desktop': OnboardingWelcome,
        'trend': OnboardingTrend,
    },
    'pt': {
        'mobile': OnboardingWelcomeMobilePt,
        'desktop': OnboardingWelcomePt,
        'trend': OnboardingTrendPt,
    },
    'de': {
        'mobile': OnboardingWelcomeMobileDe,
        'desktop': OnboardingWelcomeDe,
        'trend': OnboardingTrendDe,
    },
    'es': {
        'mobile': OnboardingWelcomeMobileEs,
        'desktop': OnboardingWelcomeEs,
        'trend': OnboardingTrendEs,
    }
}

// Onboarding modal second step images translations based on the language
export const ONBOARDING_MODAL_SECOND_STEP_IMAGES_TRANSLATIONS = {
    'en': {
        'mobile': OnboardingTrackingMobile,
        'desktop': OnboardingTracking,
        'code': OnboardingGreyCode,
    },
    'pt': {
        'mobile': OnboardingTrackingMobilePt,
        'desktop': OnboardingTrackingPt,
        'code': OnboardingGreyCodePt,
    },
    'de': {
        'mobile': OnboardingTrackingMobileDe,
        'desktop': OnboardingTrackingDe,
        'code': OnboardingGreyCodeDe,
    },
    'es': {
        'mobile': OnboardingTrackingMobileEs,
        'desktop': OnboardingTrackingEs,
        'code': OnboardingGreyCodeEs,
    }
}

// Onboarding carousel preview images translations based on the language
export const ONBOARDING_CAROUSEL_PREVIEW_IMAGES_TRANSLATIONS = {
    'en': {
        'video': OnboardingVideoPreview,
        'text': OnboardingTextPreview,
        'wi-fi': OnboardingWifiPreview,
        'mp3': OnboardingMp3Preview,
        'pdf': OnboardingPdfPreview,
        'photos': OnboardingImagePreview,
    },
    'pt': {
        'video': OnboardingVideoPreviewPt,
        'text': OnboardingTextPreviewPt,
        'wi-fi': OnboardingWifiPreviewPt,
        'mp3': OnboardingMp3PreviewPt,
        'pdf': OnboardingPdfPreviewPt,
        'photos': OnboardingImagePreviewPt,
    },
    'de': {
        'video': OnboardingVideoPreviewDe,
        'text': OnboardingTextPreviewDe,
        'wi-fi': OnboardingWifiPreviewDe,
        'mp3': OnboardingMp3PreviewDe,
        'pdf': OnboardingPdfPreviewDe,
        'photos': OnboardingImagePreviewDe,
    },
    'es': {
        'video': OnboardingVideoPreviewEs,
        'text': OnboardingTextPreviewEs,
        'wi-fi': OnboardingWifiPreviewEs,
        'mp3': OnboardingMp3PreviewEs,
        'pdf': OnboardingPdfPreviewEs,
        'photos': OnboardingImagePreviewEs,
    }
}