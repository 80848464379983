import React, { useCallback, useMemo, useRef } from 'react'
import { MdKeyboardArrowLeft } from 'react-icons/md'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { USER_CREATE_CODE_IMAGES_TRANSLATIONS } from '../../helpers/image-translations'
import { useUserCodeCreationContext } from '../../context/UserQrCreationContext'
import useReturnCodeTypesData from '../../hooks/useReturnCodeTypesData'
import { useCustomNavigate } from '../../hooks/useCustomNavigate'
import i18n from '../../i18n/config'

import DashboardCreationContent from '../DashboardCreationContent/DashboardCreationContent'
import EditDesignLandingPage from '../EditDesignLandingPage/EditDesignLandingPage'
import DashboardPreviewBlock from '../DashboardPreviewBlock/DashboardPreviewBlock'
import EditDesignQrCode from '../EditDesignQrCode/EditDesignQrCode'

import qrExampleSvg from '../../assets/icons/qrExample.svg'

const MobileCreationFlow = ({ isNewHome = false, handleConvertData = () => { } }) => {
    const user = useSelector((state) => state.app.user)

    const triggerValidationRef = useRef()

    const { t } = useTranslation()
    const { codesListData } = useReturnCodeTypesData()

    const navigate = useCustomNavigate()

    const {
        mobileCreationStep, setMobileCreationStep,
        isDynamic, setIsDynamic, userQrType, setUserQrType,
        setMobileCreation, resetMobileQrCodeStyles,
        resetMobileLandingStyles, generateCodeDashboard, resetStyles,
        staticValue, isGenerating, setType,
    } = useUserCodeCreationContext()

    const handleToPreviousStep = () => {
        setMobileCreationStep((prev) => {
            return prev - 1
        })
    }

    const handleNextStep = () => {
        setMobileCreationStep((prev) => {
            return prev + 1
        })
        window.scrollTo(0, 0);
    }

    const handleCancelCreation = () => {
        setMobileCreationStep(1)
        setMobileCreation(false)
        resetStyles()

        if (!user) {
            navigate('/')
        }
    }

    const triggerValidation = useCallback((trigger) => {
        if (trigger) {
            triggerValidationRef.current = trigger
        }
    }, [])

    const handleTriggerAndContinue = async () => {
        if (triggerValidationRef.current) {
            const result = await triggerValidationRef.current()

            if (result) {
                setMobileCreationStep((prev) => {
                    return prev + 1
                })
            }
        }
    }

    const previewImage = useMemo(() => {
        switch (userQrType) {
            case 'URL':
            case 'Text':
            case 'Wi-Fi':
            case 'Image':
            case 'PDF':
            case 'Video':
            case 'MP3': {
                return <img
                    src={
                        USER_CREATE_CODE_IMAGES_TRANSLATIONS[i18n.language]?.[userQrType]
                        ?? USER_CREATE_CODE_IMAGES_TRANSLATIONS['en']?.[userQrType]
                    }
                    className='desktop-creation-preview-image'
                    alt=""
                />
            }
            default: {
                return <img
                    src={
                        USER_CREATE_CODE_IMAGES_TRANSLATIONS[i18n.language]?.["DEFAULT"]
                        ?? USER_CREATE_CODE_IMAGES_TRANSLATIONS['en']?.["DEFAULT"]
                    }
                    className='desktop-creation-preview-image'
                    alt=""
                />
            }
        }
    }, [userQrType])

    return (
        <div style={{ minHeight: `${isNewHome ? 'unset' : '100vh'}` }} className='mobile-creation-flow-wrapper'>
            {mobileCreationStep === 1 && (
                <div style={{ minHeight: `${isNewHome ? 'calc(100vh - 161px)' : '100vh'}` }} className='mobile-creation-first-step-wrapper'>
                    <div className='mobile-creation-select-codes-list'>
                        <h5 className='mobile-creation-select-codes-title'>{t("selectCodeType")}</h5>
                        <div className='mobile-creation-select-code-types'>
                            {codesListData.map((item) => {
                                if (isDynamic) {
                                    return (
                                        <div
                                            key={item.id}
                                            onClick={() => {
                                                setUserQrType(item.nameEnglish)
                                            }}
                                            className={`mobile-creation-select-code-type ${userQrType === item.nameEnglish ? 'selected' : ''}`}
                                        >
                                            <img src={item.icon} alt="" />
                                            <span className='mobile-creation-select-code-name'>{item.name}</span>
                                        </div>
                                    )
                                } else if (!isDynamic && item.isStatic) {
                                    return (
                                        <div
                                            key={item.id}
                                            onClick={() => {
                                                setUserQrType(item.nameEnglish)
                                                setType(item.staticName)
                                            }}
                                            className={`mobile-creation-select-code-type ${userQrType === item.nameEnglish ? 'selected' : ''}`}
                                        >
                                            <img src={item.icon} alt="" />
                                            <span className='mobile-creation-select-code-name'>{item.name}</span>
                                        </div>
                                    )
                                } else {
                                    return null
                                }
                            })}
                        </div>
                    </div>
                    <div style={{ height: `${isNewHome ? 'calc(100vh - 288px)' : 'calc(100vh - 225px)'}` }} className='mobile-creation-preview-image'>
                        {isDynamic ? previewImage : <img width={250} src={qrExampleSvg} alt="" />}
                    </div>
                </div>
            )}
            {
                mobileCreationStep === 2 && (
                    <div className='mobile-creation-step-wrapper mb-20'>
                        {!isNewHome && (
                            <div className='mobile-creation-steps-header'>
                                <div onClick={handleToPreviousStep} className='mobile-creation-header-back'>
                                    <MdKeyboardArrowLeft size={28} fill="#9747FF" />
                                    <span className='mobile-creation-header-back-word'>
                                        {t("back")}
                                    </span>
                                </div>
                            </div>
                        )}
                        <DashboardCreationContent triggerValidation={triggerValidation} />
                    </div>
                )
            }
            {
                mobileCreationStep === 3 && (
                    <div className='mobile-creation-step-wrapper mb-20'>
                        {!isNewHome && (
                            <div className='mobile-creation-steps-header'>
                                <div onClick={handleToPreviousStep} className='mobile-creation-header-back'>
                                    <MdKeyboardArrowLeft size={28} fill="#9747FF" />
                                    <span className='mobile-creation-header-back-word'>
                                        {t("back")}
                                    </span>
                                </div>
                            </div>
                        )}
                        <h5 className='mobile-creation-heading'>{t("customizeQRCode")}</h5>
                        <div className=''>
                            <EditDesignQrCode mobile={true} />
                        </div>
                    </div>
                )
            }
            {
                (mobileCreationStep === 4 && isDynamic && userQrType !== 'URL') ? (
                    <div className='mobile-creation-step-wrapper mb-20'>
                        {!isNewHome && (
                            <div className='mobile-creation-steps-header'>
                                <div onClick={handleToPreviousStep} className='mobile-creation-header-back'>
                                    <MdKeyboardArrowLeft size={28} fill="#9747FF" />
                                    <span className='mobile-creation-header-back-word'>
                                        {t("back")}
                                    </span>
                                </div>
                            </div>
                        )}
                        <h5 className='mobile-creation-heading'>{t("customizeLandingPage")}</h5>
                        <div className=''>
                            <EditDesignLandingPage mobile={true} />
                        </div>
                    </div>
                ) : ((mobileCreationStep === 4 && !isDynamic) || (mobileCreationStep === 4 && isDynamic && userQrType === 'URL')) && (
                    <div className='mobile-creation-step-wrapper mb-20'>
                        {!isNewHome && (
                            <div className='mobile-creation-steps-header'>
                                <div onClick={handleToPreviousStep} className='mobile-creation-header-back'>
                                    <MdKeyboardArrowLeft size={28} fill="#9747FF" />
                                    <span className='mobile-creation-header-back-word'>
                                        {t("back")}
                                    </span>
                                </div>
                            </div>
                        )}
                        <div className='mobile-creation-preview-block'>
                            <span className='dashboard-content-creation-preview-title'>{t("preview")}</span>
                            <DashboardPreviewBlock />
                        </div>
                    </div>
                )
            }
            {
                mobileCreationStep === 5 && (
                    <div className='mobile-creation-step-wrapper mb-20'>
                        {!isNewHome && (
                            <div className='mobile-creation-steps-header'>
                                <div onClick={handleToPreviousStep} className='mobile-creation-header-back'>
                                    <MdKeyboardArrowLeft size={28} fill="#9747FF" />
                                    <span className='mobile-creation-header-back-word'>
                                        {t("back")}
                                    </span>
                                </div>
                            </div>
                        )}
                        <div className='mobile-creation-preview-block'>
                            <span className='dashboard-content-creation-preview-title'>{t("preview")}</span>
                            <DashboardPreviewBlock />
                        </div>
                    </div>
                )
            }
            {mobileCreationStep === 1 ? (
                <div className='mobile-creation-flow-actions-block-first-step'>
                    <div className='desktop-creation-dynamic-switch-block justify-between'>
                        <span className='desktop-creation-dynamic-switch-label'>{isDynamic ? t("dynamic") : t("static")}</span>
                        <div onClick={() => setIsDynamic(!isDynamic)} className={`overview-panel-stats-header-unique-scans-switch ${isDynamic ? 'active' : ''}`}>
                            <div className={`overview-panel-switch-circle ${isDynamic ? 'active' : ''}`}></div>
                        </div>
                    </div>
                    <div className='flex w-full justify-between gap-2'>
                        {mobileCreationStep === 1 && !isNewHome && (
                            <button onClick={handleCancelCreation} className='mobile-creation-flow-cancel-btn'>{t("cancel")}</button>
                        )}
                        <button onClick={handleNextStep} className='mobile-creation-flow-next-btn'>{t("next")}</button>
                    </div>
                </div>
            ) : (
                <div className='mobile-creation-flow-actions-block'>
                    {mobileCreationStep === 1 && !isNewHome && (
                        <button onClick={handleCancelCreation} className='mobile-creation-flow-cancel-btn'>{t("cancel")}</button>
                    )}
                    {mobileCreationStep === 3 && (
                        <button onClick={resetMobileQrCodeStyles} className='mobile-creation-flow-cancel-btn'>{t("resetChanges")}</button>
                    )}
                    {mobileCreationStep === 4 && isDynamic && userQrType !== 'URL' && (
                        <button onClick={resetMobileLandingStyles} className='mobile-creation-flow-cancel-btn'>{t("resetChanges")}</button>
                    )}
                    {mobileCreationStep === 2 && (
                        <button onClick={handleTriggerAndContinue} className='mobile-creation-flow-next-btn'>{t("next")}</button>
                    )}
                    {(mobileCreationStep === 1 || mobileCreationStep === 3 || (mobileCreationStep === 4 && isDynamic && userQrType !== 'URL')) && (
                        <button onClick={handleNextStep} className='mobile-creation-flow-next-btn'>{t("next")}</button>
                    )}
                    {((mobileCreationStep === 4 && !isDynamic) || (mobileCreationStep === 5 && isDynamic) || (mobileCreationStep === 4 && isDynamic && userQrType === 'URL')) && (
                        <button
                            disabled={(staticValue && !isGenerating) ? false : true}
                            onClick={isNewHome ? () => navigate('/sign-up?upgrade=true&download=true') : () => generateCodeDashboard(handleConvertData)}
                            className='mobile-creation-flow-next-btn relative'
                        >
                            {isGenerating && (
                                <svg style={{ position: 'absolute', left: 'calc(50% - 80px)' }} className='stripe-loader' width={25} height={25} fill="#9747FF" viewBox="25 25 50 50">
                                    <circle r="20" cy="50" cx="50"></circle>
                                </svg>
                            )}
                            {t("generate")}
                        </button>
                    )}
                </div>
            )}
        </div >
    )
}

export default MobileCreationFlow