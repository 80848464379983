import { useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import React from 'react'

import { useCustomNavigate } from '../../hooks/useCustomNavigate'

import paymentErrorSvg from '../../assets/icons/payment-error.svg'

const UpgradeError = () => {
    const subscription = useSelector((state) => state.app.subscription)

    const [searchParams] = useSearchParams();

    const { t } = useTranslation()

    const navigate = useCustomNavigate()

    const handleTryAgain = () => {
        const priceId = searchParams.get('priceId')
        const planName = searchParams.get('planName')
        const isNewUser = searchParams.get('isNewUser')

        navigate(`/upgrade-plan/checkout/${priceId}/${planName}?isNewUser=${isNewUser}`)
    }

    return (
        <div className='upgrade-success-wrapper'>
            <img className='upgrade-success-img' src={paymentErrorSvg} alt="" />
            <span className='upgrade-success-text upgrade-text-title-mobile'>{t("somethingWentWrong")}</span>
            <span className='upgrade-error-underline-text mobile-upgrade-text'>
                {t("weWereUnableToProcessYourPayment")}
                <span onClick={handleTryAgain} className='upgrade-error-try-again'>{t("tryAgain")}</span>
            </span>
            <button onClick={() => navigate(subscription?.new_flow ? '/upgrade-plan-new' : '/upgrade-plan')} className='upgrade-success-button'>
                {t("backToUpgradePlans")}
            </button>
            <span className='upgrade-error-underline-text desktop-upgrade-text'>
                {t("weWereUnableToProcessYourPayment")}
                <span onClick={handleTryAgain} className='upgrade-error-try-again'>
                    {t("tryAgain")}
                </span>
            </span>
        </div >
    )
}

export default UpgradeError