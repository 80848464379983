import { useDispatch, useSelector } from 'react-redux'
import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal } from 'flowbite-react'

import { ACCOUNT_DELETION_REASONS_TRANSLATIONS } from '../../helpers/translation-constants'
import { useCustomNavigate } from '../../hooks/useCustomNavigate'
import { userSignOut } from '../../firebase/auth/googleAuth'
import { signOut } from '../../redux/appSlice'
import { auth } from '../../firebase/config'
import {
    api,
    useDeleteUserProfileMutation,
    useSendEmailSendgridAccountDeletionConfirmationMutation,
    useSendEmailSendgridSorryToSeeYouGoMutation,
    useSendFeedbackOnDeleteAccountMutation
} from '../../api/api'

import ArrowBottom from '../../assets/icons/arrowbottom.svg'

const ACCOUNT_DELETION_REASONS = [
    'Found an alternative service',
    'Too expensive',
    'Limited customization options',
    'I didn’t use it enough',
    'Limited features',
    'Other (please specify)'
]

const AccountDeletionFeedbackModal = ({ show, onClose }) => {
    const [selectedReason, setSelectedReason] = useState('Select')
    const [expandDropdown, setExpandDropdown] = useState(false)
    const [otherText, setOtherText] = useState('')
    const user = useSelector((state) => state.app.user)

    const rootRef = useRef(null)

    const { t } = useTranslation()

    const dispatch = useDispatch()
    const navigate = useCustomNavigate()

    const [deleteUserProfileEmail] = useSendEmailSendgridAccountDeletionConfirmationMutation()
    const [sorryToSeeYouGo] = useSendEmailSendgridSorryToSeeYouGoMutation()
    const [sendFeedback] = useSendFeedbackOnDeleteAccountMutation()
    const [deleteUserProfile] = useDeleteUserProfileMutation()

    const isSubmitButtonDisabled = selectedReason === 'Select' || (selectedReason === 'Other (please specify)' && !otherText)

    const handleDeleteAndClose = async () => {
        await deleteUserProfileEmail()
        await sorryToSeeYouGo()
        await deleteUserProfile()
        await auth?.currentUser?.delete()
        await userSignOut()
        dispatch(signOut())
        dispatch(api.util.resetApiState());
        localStorage.removeItem('showTrialAlert')
        navigate('/sign-in')
        onClose()
    }

    const handleSubmitFeedback = async () => {
        await sendFeedback({ reason: selectedReason, text: otherText, email: user.email })
        await deleteUserProfileEmail()
        await sorryToSeeYouGo()
        await deleteUserProfile()
        await auth?.currentUser?.delete()
        await userSignOut()
        dispatch(signOut())
        dispatch(api.util.resetApiState());
        localStorage.removeItem('showTrialAlert')
        navigate('/sign-in')
        onClose()
    }

    return (
        <div ref={rootRef}>
            <Modal
                show={show}
                size="md"
                popup="false"
                position='center'
                onClose={onClose}
                style={{ height: '100vh' }}
                className='enter-password-modal'
                root={rootRef.current ?? undefined}
            >
                <Modal.Body className='trial-end-modal-body padding-top-24 overflow-unset-important'>
                    <div className='confirm-account-deletion-modal-wrapper'>
                        <h5 className='confirm-account-deletion-modal-title'>{t("yourFeedbackHelpsUsImprove")}</h5>
                        <p className='confirm-account-deletion-modal-description'>{t("wouldYouMindTellingUsWhyYouAreLeaving")}</p>
                        <div className={`profile-settings-input-wrapper items-center relative ${expandDropdown ? 'account-deletion-reason-block-active' : ''}`}>
                            <div
                                onClick={() => setExpandDropdown(!expandDropdown)}
                                className={`flex w-full items-center justify-between cursor-pointer`}
                            >
                                <span className={`account-deletion-feedback-modal-selected-reason ${selectedReason === 'Select' ? 'select' : ''}`}>{selectedReason}</span>
                                <img style={{ transform: `rotate(${expandDropdown ? '180deg' : '0deg'})` }} src={ArrowBottom} alt="" />
                            </div>
                            {expandDropdown && (
                                <div className='confirm-account-deletion-modal-reason-dropdown'>
                                    {ACCOUNT_DELETION_REASONS.map((reason, index) => (
                                        <div
                                            key={index}
                                            onClick={() => { setSelectedReason(reason); setExpandDropdown(false) }}
                                            className='account-deletion-feedback-modal-reason'
                                        >
                                            {t(`${ACCOUNT_DELETION_REASONS_TRANSLATIONS[reason]}`)}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                        {selectedReason === 'Other (please specify)' && (
                            <div className='confirm-account-deletion-model-other-input-block'>
                                <textarea
                                    placeholder={t("tellUsMoreAboutYourExperience")}
                                    className='confirm-account-deletion-other-textarea-field'
                                    maxLength={500}
                                    value={otherText}
                                    onChange={(e) => setOtherText(e.target.value)}
                                />
                                <span className='confirm-account-deletion-modal-text-length'>{otherText.length}/500 {t("characters")}</span>
                            </div>
                        )}
                        <button
                            onClick={handleSubmitFeedback}
                            disabled={isSubmitButtonDisabled}
                            className='confirm-account-deletion-modal-button'
                        >
                            {t("submitFeedback")}
                        </button>
                        <button onClick={handleDeleteAndClose} className='confirm-account-deletion-modal-keep'>{t("noThanks")}</button>
                    </div>
                </Modal.Body>
            </Modal>
        </div >
    )
}

export default AccountDeletionFeedbackModal