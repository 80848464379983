import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import React, { useState } from 'react'
import { Label } from 'flowbite-react';

import { useSendEmailSendgridContactUsEmailMutation } from '../../api/api';
import { contactUsSchema } from '../../helpers/validation';
import useToasts from '../../hooks/useToasts';

import overviewPanelMobileBack from '../../assets/icons/overview-panel-mobile-back.svg'

const ContactUs = ({ fromSettings = false, isMobile, setActiveTab }) => {
    const [loading, setLoading] = useState(false)
    const user = useSelector((state) => state.app.user)

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(contactUsSchema)
    });

    const { t } = useTranslation()
    const { contactEmailToast, errorToast } = useToasts()

    const [sendEmail] = useSendEmailSendgridContactUsEmailMutation()

    const handleNavigateBackToMenu = () => {
        if (isMobile) {
            setActiveTab('')
        }
    }

    const handleSendEmail = (data) => {
        setLoading(true)

        sendEmail(data).then((res) => {
            if ('error' in res) {
                errorToast()
                setLoading(false)
            } else {
                contactEmailToast()
                setLoading(false)
            }
        })
    }

    return (
        <>
            {fromSettings ? (
                <section style={{ minHeight: 'calc(100vh - 68px)' }} className='contact-us-section settings'>
                    {isMobile && (
                        <div className='settings-mobile-tab-header pb-2'>
                            <h5 onClick={handleNavigateBackToMenu} className='settings-tab-title'>
                                {isMobile && (
                                    <img width={20} src={overviewPanelMobileBack} alt="" />
                                )}
                                {t("contactUs")}
                                {isMobile && (
                                    <div style={{ width: '20px' }} />
                                )}
                            </h5>
                        </div>
                    )}
                    <form onSubmit={handleSubmit(handleSendEmail)} className='contact-us-form'>
                        <div className='flex flex-col'>
                            <Label
                                value={t("emailAddress")}
                                className='mb-1'
                            />
                            <input
                                type='text'
                                placeholder={t("emailAddress")}
                                className={errors.email ? 'form-input-error-border form-input-dashboard' : 'form-input-dashboard'}
                                {...register("email")}
                            />
                            {errors.email && <p className='error-text'>{errors.email?.message}</p>}
                        </div>
                        <div className='flex flex-col'>
                            <Label
                                value={t("subject")}
                                className='mb-1'
                            />
                            <input
                                type='text'
                                placeholder={t("subject")}
                                className={errors.email ? 'form-input-error-border form-input-dashboard' : 'form-input-dashboard'}
                                {...register("subject")}
                            />
                            {errors.subject && <p className='error-text'>{errors.subject?.message}</p>}
                        </div>
                        <div className='flex flex-col'>
                            <Label
                                value={t("text")}
                                className='mb-1'
                            />
                            <textarea
                                type='text'
                                placeholder={t("text")}
                                style={{ height: '180px' }}
                                className={errors.description ? 'form-input-dashboard-textarea form-input-error-border' : 'form-input-dashboard-textarea'}
                                {...register("description")}
                            />
                            {errors.description && <p className='error-text'>{errors.description?.message}</p>}
                        </div>
                        <button
                            className="contact-us-button relative"
                            disabled={loading}
                        >
                            {loading && (
                                <svg style={{ position: 'absolute', left: 'calc(50% - 70px)' }} className='stripe-loader' width={25} height={25} fill="#9747FF" viewBox="25 25 50 50">
                                    <circle r="20" cy="50" cx="50"></circle>
                                </svg>
                            )}
                            {t("send")}
                        </button>
                    </form>
                </section>
            ) : (
                <div className={`purple-corners-block-wrapper ${user ? '' : 'no-user'}`}>
                    <main className='product-information-main'>
                        <section className='contact-us-section'>
                            <form onSubmit={handleSubmit(handleSendEmail)} className='contact-us-form'>
                                <div className='flex flex-col'>
                                    <Label
                                        value={t("emailAddress")}
                                        className='mb-1'
                                    />
                                    <input
                                        type='text'
                                        placeholder={t("emailAddress")}
                                        className={errors.email ? 'form-input-error-border form-input-dashboard' : 'form-input-dashboard'}
                                        {...register("email")}
                                    />
                                    {errors.email && <p className='error-text'>{errors.email?.message}</p>}
                                </div>
                                <div className='flex flex-col'>
                                    <Label
                                        value={t("subject")}
                                        className='mb-1'
                                    />
                                    <input
                                        type='text'
                                        placeholder={t("subject")}
                                        className={errors.email ? 'form-input-error-border form-input-dashboard' : 'form-input-dashboard'}
                                        {...register("subject")}
                                    />
                                    {errors.subject && <p className='error-text'>{errors.subject?.message}</p>}
                                </div>
                                <div className='flex flex-col'>
                                    <Label
                                        value={t("text")}
                                        className='mb-1'
                                    />
                                    <textarea
                                        type='text'
                                        placeholder={t("text")}
                                        style={{ height: '180px' }}
                                        className={errors.description ? 'form-input-dashboard-textarea form-input-error-border' : 'form-input-dashboard-textarea'}
                                        {...register("description")}
                                    />
                                    {errors.description && <p className='error-text'>{errors.description?.message}</p>}
                                </div>
                                <button
                                    className="contact-us-button relative"
                                    disabled={loading}
                                >
                                    {loading && (
                                        <svg style={{ position: 'absolute', left: 'calc(50% - 70px)' }} className='stripe-loader' width={25} height={25} fill="#9747FF" viewBox="25 25 50 50">
                                            <circle r="20" cy="50" cx="50"></circle>
                                        </svg>
                                    )}
                                    {t("send")}
                                </button>
                            </form>
                        </section>
                    </main>
                </div>
            )}
        </>
    )
}

export default ContactUs