import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

import useGenerateUrl from '../../hooks/useGenerateUrl'

import overviewPanelMobileBack from '../../assets/icons/overview-panel-mobile-back.svg'

const TermsOfService = ({ fromSettings = false, setActiveTab = () => { }, isMobile }) => {
    const user = useSelector((state) => state.app.user)

    const { t } = useTranslation()
    const generateUrl = useGenerateUrl()

    const handleNavigateBackToMenu = () => {
        if (isMobile) {
            setActiveTab('')
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            {fromSettings ? (
                <section className="product-information-section terms-of-service-section">
                    {isMobile && (
                        <div className='settings-mobile-tab-header pb-2 pt-3 mb-4'>
                            <h5 onClick={handleNavigateBackToMenu} className='settings-tab-title'>
                                {isMobile && (
                                    <img width={20} src={overviewPanelMobileBack} alt="" />
                                )}
                                {t("termsAndConditionsPageTitle")}
                                {isMobile && (
                                    <div style={{ width: '20px' }} />
                                )}
                            </h5>
                        </div>
                    )}
                    <h2 className="mb-4 text-2xl font-extrabold">
                        <strong>{t("termsAndConditionsPageTitle")}</strong>
                    </h2>
                    <h3 className="my-4 text-xl font-bold">
                        <strong>{t("acceptanceOfTerms")}</strong>
                    </h3>
                    <p>
                        {t("welcomeToQRCodeDeveloperByAccessingAndUsingWebsite")}
                    </p>
                    <h4 className='my-2 text-md font-medium'>
                        <strong>{t("agreementAsAContract")}</strong>
                    </h4>
                    <p>
                        {t("theseTermsConstituteALegallyBindingContract")}
                    </p>
                    <h4 className='my-2 text-md font-medium'>
                        <strong>{t("acceptanceByUsingTheServices")}</strong>
                    </h4>
                    <p>
                        {t("yourUseOfTheServicesSignifiesYourAcceptance")}
                    </p>
                    <h3 className="my-4 text-xl font-bold">
                        <strong>{t("2PrivacyPolicy")}</strong>
                    </h3>
                    <p>
                        {t("our")} <span style={{ color: '#9747FF', cursor: 'pointer' }} onClick={() => setActiveTab('Privacy Policy')}>{t("privacyPolicy")}</span> {t("outlinesHowWeCollectUseAndShare")} <span style={{ color: '#9747FF', cursor: 'pointer' }} onClick={() => setActiveTab('Privacy Policy')}>{t("privacyPolicy")}</span> {t("forDetailsOnDataProcessingObligationsAndPractices")}
                    </p>
                    <h3 className="my-4 text-xl font-bold">
                        <strong>{t("eligibility")}</strong>
                    </h3>
                    <p>
                        {t("toUseOurServicesYouMustBeAtLeastEighteen")}
                    </p>
                    <h3 className="my-4 text-xl font-bold">
                        <strong>{t("conditionsOfUseOfTheWebsite")}</strong>
                    </h3>
                    <p>
                        {t("theUserCommitsToUtilizingTheQRCodeDeveloper")}
                    </p>
                    <h3 className="my-4 text-xl font-bold">
                        <strong>{t("subscriptionAndPayments")}</strong>
                    </h3>
                    <h4 className='my-2 text-md font-medium'>
                        <strong>{t("51PaymentMethod")}</strong>
                    </h4>
                    <p>
                        {t("onlinePaymentOfThePurchasedServicesCanBeMade")}
                    </p>
                    <h4 className='my-2 text-md font-medium'>
                        <strong>{t("52Subscriptions")}</strong>
                    </h4>
                    <p>
                        {t("subscriptionsAutomaticallyRenewMaintainingTheSameDuration")}
                    </p>
                    <h4 className='my-2 text-md font-medium'>
                        <strong>{t("53FreeTrial")}</strong>
                    </h4>
                    <p>
                        {t("newUsersMayBeOfferedAOneTimeFreeTrial")}
                    </p>
                    <h4 className='my-2 text-md font-medium'>
                        <strong>{t("54ChangesAndCancellation")}</strong>
                    </h4>
                    <p>{t("toModifyOrCancelYourSubscriptionLogIntoYourAccount")}</p>
                    <h4 className='my-2 text-md font-medium'>
                        <strong>{t("55LimitedRefunds")}</strong>
                    </h4>
                    <p>
                        {t("weOfferA30daysMoneyBackSatisfactionGuarantee")}
                    </p>
                    <h3 className="my-4 text-xl font-bold">
                        <strong>{t("thirdPartyWebsites")}</strong>
                    </h3>
                    <p>
                        {t("weMayLinkToThirdPartyWebsites")}
                    </p>
                    <h3 className="my-4 text-xl font-bold">
                        <strong>{t("intellectualProperty")}</strong>
                    </h3>
                    <p>
                        {t("ourTrademarksCopyrightsAndIntellectualProperty")}
                    </p>
                    <h3 className="my-4 text-xl font-bold">
                        <strong>{t("customerService")}</strong>
                    </h3>
                    <p>
                        {t("forAssistanceContactOurCustomerSupportTeam")}
                    </p>
                    <h3 className="my-4 text-xl font-bold">
                        <strong>{t("termAndTermination")}</strong>
                    </h3>
                    <h4 className='my-2 text-md font-medium'>
                        <strong>{t("commencementAndDuration")}</strong>
                    </h4>
                    <p>
                        {t("theseTermsCommenceUponYourUseOfOurServices")}
                    </p>
                    <h4 className='my-2 text-md font-medium'>
                        <strong>{t("terminationByQRCodeDeveloper")}</strong>
                    </h4>
                    <p>
                        {t("weMayUnilaterallyAndImmediatelyTerminateTheseTerms")}
                    </p>
                    <h4 className='my-2 text-md font-medium'>
                        <strong>{t("effectOfTermination")}</strong>
                    </h4>
                    <p>
                        {t("uponTerminationAllGrantedRightsCease")}
                    </p>
                    <h3 className="my-4 text-xl font-bold">
                        <strong>{t("indemnification")}</strong>
                    </h3>
                    <p>
                        {t("youAgreeToIndemnifyDefendAndHoldHarmless")}
                    </p>
                    <h3 className="my-4 text-xl font-bold">
                        <strong>{t("disclaimerOfWarranties")}</strong>
                    </h3>
                    <p>
                        {t("qrCodeDeveloperDisclaimsAllWarrantiesExpress")}
                    </p>
                    <h3 className="my-4 text-xl font-bold">
                        <strong>{t("userContent")}</strong>
                    </h3>
                    <h4 className='my-2 text-md font-medium'>
                        <strong>{t("accessAndVisibilityOfTheQRCodes")}</strong>
                    </h4>
                    <p>
                        {t("usersUnderstandThatQRCodesCreatedViaQRCode")}
                    </p>
                    <h3 className="my-4 text-xl font-bold">
                        <strong>{t("governingLawAndDisputeResolution")}</strong>
                    </h3>
                    <h4 className='my-2 text-md font-medium'>
                        <strong>{t("informalDisputeResolution")}</strong>
                    </h4>
                    <p>
                        {t("usersWithConcernsOrDisputesShouldContact")}
                    </p>
                    <h4 className='my-2 text-md font-medium'>
                        <strong>{t("governingLawAndLocation")}</strong>
                    </h4>
                    <p>
                        {t("theseTermsAreExclusivelyGovernedByTheLaws")}
                    </p>
                    <h4 className='my-2 text-md font-medium'>
                        <strong>{t("noClassActions")}</strong>
                    </h4>
                    <p>
                        {t("disputesShallBeResolvedOnAnIndividualBasis")}
                    </p>
                    <h3 className="my-4 text-xl font-bold">
                        <strong>{t("14customerService")}</strong>
                    </h3>
                    <p>
                        {t("forAnyClarificationIncidentOrClaim")}
                    </p>
                </section>
            ) : (
                <div className={`purple-corners-block-wrapper ${user ? '' : 'no-user'}`}>
                    <main className="product-information-main">
                        <section className="product-information-section terms-of-service-section">
                            <h2 className="my-4 text-2xl font-extrabold">
                                <strong>{t("termsAndConditionsPageTitle")}</strong>
                            </h2>
                            <h3 className="my-4 text-xl font-bold">
                                <strong>{t("acceptanceOfTerms")}</strong>
                            </h3>
                            <p>
                                {t("welcomeToQRCodeDeveloperByAccessingAndUsingWebsite")}
                            </p>
                            <h4 className='my-2 text-md font-medium'>
                                <strong>{t("agreementAsAContract")}</strong>
                            </h4>
                            <p>
                                {t("theseTermsConstituteALegallyBindingContract")}
                            </p>
                            <h4 className='my-2 text-md font-medium'>
                                <strong>{t("acceptanceByUsingTheServices")}</strong>
                            </h4>
                            <p>
                                {t("yourUseOfTheServicesSignifiesYourAcceptance")}
                            </p>
                            <h3 className="my-4 text-xl font-bold">
                                <strong>{t("2PrivacyPolicy")}</strong>
                            </h3>
                            <p>
                                {t("our")} <Link to={generateUrl('/privacy-policy')} style={{ color: '#9747FF', cursor: 'pointer' }} onClick={() => setActiveTab('Privacy Policy')}>{t("privacyPolicy")}</Link> {t("outlinesHowWeCollectUseAndShare")} <Link to={generateUrl("/privacy-policy")} style={{ color: '#9747FF', cursor: 'pointer' }} onClick={() => setActiveTab('Privacy Policy')}>{t("privacyPolicy")}</Link> {t("forDetailsOnDataProcessingObligationsAndPractices")}
                            </p>
                            <h3 className="my-4 text-xl font-bold">
                                <strong>{t("eligibility")}</strong>
                            </h3>
                            <p>
                                {t("toUseOurServicesYouMustBeAtLeastEighteen")}
                            </p>
                            <h3 className="my-4 text-xl font-bold">
                                <strong>{t("conditionsOfUseOfTheWebsite")}</strong>
                            </h3>
                            <p>
                                {t("theUserCommitsToUtilizingTheQRCodeDeveloper")}
                            </p>
                            <h3 className="my-4 text-xl font-bold">
                                <strong>{t("subscriptionAndPayments")}</strong>
                            </h3>
                            <h4 className='my-2 text-md font-medium'>
                                <strong>{t("51PaymentMethod")}</strong>
                            </h4>
                            <p>
                                {t("onlinePaymentOfThePurchasedServicesCanBeMade")}
                            </p>
                            <h4 className='my-2 text-md font-medium'>
                                <strong>{t("52Subscriptions")}</strong>
                            </h4>
                            <p>
                                {t("subscriptionsAutomaticallyRenewMaintainingTheSameDuration")}
                            </p>
                            <h4 className='my-2 text-md font-medium'>
                                <strong>{t("53FreeTrial")}</strong>
                            </h4>
                            <p>
                                {t("newUsersMayBeOfferedAOneTimeFreeTrial")}
                            </p>
                            <h4 className='my-2 text-md font-medium'>
                                <strong>{t("54ChangesAndCancellation")}</strong>
                            </h4>
                            <p>
                                {t("toModifyOrCancelYourSubscriptionLogIntoYourAccount")}
                            </p>
                            <h4 className='my-2 text-md font-medium'>
                                <strong>{t("55LimitedRefunds")}</strong>
                            </h4>
                            <p>
                                {t("weOfferA30daysMoneyBackSatisfactionGuarantee")}
                            </p>
                            <h3 className="my-4 text-xl font-bold">
                                <strong>{t("thirdPartyWebsites")}</strong>
                            </h3>
                            <p>
                                {t("weMayLinkToThirdPartyWebsites")}
                            </p>
                            <h3 className="my-4 text-xl font-bold">
                                <strong>{t("intellectualProperty")}</strong>
                            </h3>
                            <p>
                                {t("ourTrademarksCopyrightsAndIntellectualProperty")}
                            </p>
                            <h3 className="my-4 text-xl font-bold">
                                <strong>{t("customerService")}</strong>
                            </h3>
                            <p>
                                {t("forAssistanceContactOurCustomerSupportTeam")}
                            </p>
                            <h3 className="my-4 text-xl font-bold">
                                <strong>{t("termAndTermination")}</strong>
                            </h3>
                            <h4 className='my-2 text-md font-medium'>
                                <strong>{t("commencementAndDuration")}</strong>
                            </h4>
                            <p>
                                {t("theseTermsCommenceUponYourUseOfOurServices")}
                            </p>
                            <h4 className='my-2 text-md font-medium'>
                                <strong>{t("terminationByQRCodeDeveloper")}</strong>
                            </h4>
                            <p>
                                {t("weMayUnilaterallyAndImmediatelyTerminateTheseTerms")}
                            </p>
                            <h4 className='my-2 text-md font-medium'>
                                <strong>{t("effectOfTermination")}</strong>
                            </h4>
                            <p>
                                {t("uponTerminationAllGrantedRightsCease")}
                            </p>
                            <h3 className="my-4 text-xl font-bold">
                                <strong>{t("indemnification")}</strong>
                            </h3>
                            <p>
                                {t("youAgreeToIndemnifyDefendAndHoldHarmless")}
                            </p>
                            <h3 className="my-4 text-xl font-bold">
                                <strong>{t("disclaimerOfWarranties")}</strong>
                            </h3>
                            <p>
                                {t("qrCodeDeveloperDisclaimsAllWarrantiesExpress")}
                            </p>
                            <h3 className="my-4 text-xl font-bold">
                                <strong>{t("userContent")}</strong>
                            </h3>
                            <h4 className='my-2 text-md font-medium'>
                                <strong>{t("accessAndVisibilityOfTheQRCodes")}</strong>
                            </h4>
                            <p>
                                {t("usersUnderstandThatQRCodesCreatedViaQRCode")}
                            </p>
                            <h3 className="my-4 text-xl font-bold">
                                <strong>{t("governingLawAndDisputeResolution")}</strong>
                            </h3>
                            <h4 className='my-2 text-md font-medium'>
                                <strong>{t("informalDisputeResolution")}</strong>
                            </h4>
                            <p>
                                {t("usersWithConcernsOrDisputesShouldContact")}
                            </p>
                            <h4 className='my-2 text-md font-medium'>
                                <strong>{t("governingLawAndLocation")}</strong>
                            </h4>
                            <p>
                                {t("theseTermsAreExclusivelyGovernedByTheLaws")}
                            </p>
                            <h4 className='my-2 text-md font-medium'>
                                <strong>{t("noClassActions")}</strong>
                            </h4>
                            <p>
                                {t("disputesShallBeResolvedOnAnIndividualBasis")}
                            </p>
                            <h3 className="my-4 text-xl font-bold">
                                <strong>{t("14customerService")}</strong>
                            </h3>
                            <p>
                                {t("forAnyClarificationIncidentOrClaim")}
                            </p>
                        </section>
                    </main>
                </div>
            )}
        </>
    )
}

export default TermsOfService