import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BiSearch } from 'react-icons/bi'
import { Modal } from 'react-bootstrap'
import { CgClose } from 'react-icons/cg'
import { IoArrowBack } from 'react-icons/io5'

import { useUpdateUserSettingsMutation } from '../../api/api'
import useReturnCodeSvg from '../../hooks/useReturnCodeSvg'

import gradientQrSvg from '../../assets/icons/gradientqr.svg'

const ConvertStaticCodesToDynamicModal = ({ show, onClose, staticCodes, handleConvertAllCodesToDynamic, handleConvertSelectedCodesToDynamic }) => {
    const [selectCodesManually, setSelectCodesManually] = useState(false)
    const [selectedCodes, setSelectedCodes] = useState([])
    const [filteredCodes, setFilteredCodes] = useState(staticCodes || [])
    const [searchValue, setSearchValue] = useState('')

    const rootRef = useRef(null)

    const { t } = useTranslation()
    const { staticIcons } = useReturnCodeSvg()

    const [updateSettings] = useUpdateUserSettingsMutation()

    const handleConvertAllCodes = () => {
        handleConvertAllCodesToDynamic()
        onClose()
    }

    const handleDontShowAgain = () => {
        updateSettings({
            data: {
                dont_show_convert_modal: true,
            }
        })
        onClose()
    }

    const handleSelectCodesManually = () => {
        setSelectCodesManually(true)
    }

    const handleSelectDeselectAll = () => {
        if (selectedCodes.length === staticCodes.length) {
            setSelectedCodes([])
        } else {
            setSelectedCodes(staticCodes.map(code => code.id))
        }
    }

    const handleSelectCodeToConvert = (event, id) => {
        if (event.target.checked) {
            setSelectedCodes([...selectedCodes, id])
        } else {
            setSelectedCodes(selectedCodes.filter(code => code !== id))
        }
    }

    const handleConvertSelectedCodes = () => {
        const selectedCodesToConvert = staticCodes.filter(code => selectedCodes.includes(code.id))
        handleConvertSelectedCodesToDynamic(selectedCodesToConvert)
        onClose()
    }

    useEffect(() => {
        if (staticCodes) {
            const filteredCodes = staticCodes.filter(code => code.name.toLowerCase().includes(searchValue.toLowerCase()))
            setFilteredCodes(filteredCodes)
        }
    }, [staticCodes, searchValue])

    return (
        <div ref={rootRef}>
            <Modal
                show={show}
                size="sm"
                popup="false"
                centered
                onClose={onClose}
                style={{ height: '100vh' }}
                className='convert-static-codes-to-dynamic-modal'
                root={rootRef.current ?? undefined}
            >
                <Modal.Body className='convert-static-codes-to-dynamic-modal-body padding-top-24'>
                    <div className='convert-static-codes-header-block'>
                        {selectCodesManually ? (
                            <IoArrowBack
                                size={24}
                                color={"#7D8898"}
                                onClick={() => setSelectCodesManually(false)}
                                className='convert-static-codes-header-back'
                            />
                        ) : (
                            <CgClose
                                size={24}
                                color={"#7D8898"}
                                onClick={onClose}
                                className='convert-static-codes-header-close'
                            />
                        )}
                    </div>
                    {selectCodesManually ? (
                        <>
                            <h5 className='convert-static-codes-title'>
                                {t("selectStaticQRCodesToConvertToDynamic")}
                            </h5>
                            <p className='convert-static-codes-description'>
                                {t("allStaticCodesThatAreConvertedToDynamicWillBeAvailable")}
                            </p>
                            <div className='select-codes-to-convert-search-block'>
                                <BiSearch size={24} color="#7D8898" />
                                <input
                                    value={searchValue}
                                    onChange={(e) => setSearchValue(e.target.value)}
                                    className='select-codes-to-convert-search-input'
                                    placeholder={t("search")}
                                    type="text"
                                />
                            </div>
                            <div className='select-codes-to-convert-list'>
                                <span
                                    onClick={handleSelectDeselectAll}
                                    className='select-codes-all-text'
                                >
                                    {selectedCodes.length === staticCodes.length ? t("deselectAll") : t("selectAll")}
                                </span>
                                {filteredCodes.map((code, index) => (
                                    <div key={index} style={{ padding: '0' }} className='global-search-codes-item-block'>
                                        <div className='dashboard-code-row-with-gap'>
                                            <div className='global-search-static-image-wrapper'>
                                                <img className='global-search-qr-code-icon' src={staticIcons[code.data.kind]} alt="" />
                                            </div>
                                            <div className='global-search-qr-code-main-info'>
                                                <div className='code-main-info-name'>
                                                    <span className='global-search-qr-code-name md:text-truncate'>{code.name}</span>
                                                </div>
                                                <span className='global-search-qr-code-date'>{t("static")}</span>
                                            </div>
                                        </div>
                                        <div className='dashboard-code-row-with-gap'>
                                            <input
                                                onChange={(e) => handleSelectCodeToConvert(e, code.id)}
                                                checked={selectedCodes.includes(code.id)}
                                                className='select-code-to-convert-checkbox'
                                                type="checkbox"
                                            />
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <button
                                onClick={handleConvertSelectedCodes}
                                disabled={selectedCodes.length === 0}
                                className='select-codes-to-convert-btn-convert'
                            >
                                {t("convert")}
                            </button>
                        </>
                    ) : (
                        <>
                            <h5 className='convert-static-codes-title'>
                                {t("convertYourDefault")} {staticCodes?.length} <span className='convert-static-codes-title-grey'>{t("staticLowercase")}</span> {t("qrCodesTo")} <span className='convert-static-codes-title-pink'>{t("dynamicLowercase")}!</span>
                            </h5>
                            <p className='convert-static-codes-description'>
                                {t("gainAccessToInDepthScanStatisticsWhenYouConvertToDynamic")}
                            </p>
                            <div style={{ margin: '0 auto' }} className='home-convert-code-qr-image-gradient-wrapper'>
                                <div className='convert-code-qr-image-wrapper z-10'>
                                    <img className='convert-code-qr-image' src={gradientQrSvg} alt="" />
                                </div>
                                <div className='home-convert-code-graient'></div>
                            </div>
                            <div className='convert-static-codes-buttons-list'>
                                <button onClick={handleConvertAllCodes} className='convert-static-codes-all-btn'>{t("convertAllToDynamic")}</button>
                                <button onClick={handleSelectCodesManually} className='convert-static-codes-manually-btn'>{t("selectCodesManually")}</button>
                                <button onClick={handleDontShowAgain} className='convert-static-codes-not-show-btn'>{t("doNotShowThisAgain")}</button>
                            </div>
                            <div className='convert-static-codes-disclaimer-text'>
                                <span className='convert-static-codes-disclaimer-title'>{t("disclaimer")}</span> {t("onceYourTrialEndsYouWillNeedToUpgradeYourPlan")}
                            </div>
                        </>
                    )}
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default ConvertStaticCodesToDynamicModal