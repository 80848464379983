import { Modal } from 'flowbite-react'
import React, { useEffect, useState } from 'react'
import managePlanCloseSvg from '../../assets/icons/manage-plan-close.svg'
import { useSelector } from 'react-redux'
import { useManageSubscriptionMutation } from '../../api/api'
import newLogo from '../../assets/icons/newlogo.svg'
import { trialPeriodEndDate } from '../../helpers/functions'
import { useCustomNavigate } from '../../hooks/useCustomNavigate'

const ManagePlanModal = ({ show, onClose }) => {
    const subscription = useSelector((state) => state.app.subscription)
    const [planPrice, setPlanPrice] = useState(0)
    const [planName, setPlanName] = useState('')
    const [trialDaysLeft, setTrialDaysLeft] = useState(7)

    const navigate = useCustomNavigate()

    const [manageSubscription] = useManageSubscriptionMutation()

    const handleNavigateToUpgradePlan = async () => {
        await onClose()

        if (subscription?.new_flow) {
            navigate('/upgrade-plan-new')
        } else {
            navigate('/upgrade-plan')
        }
    }

    const handleManagePlan = async () => {
        const returnUrl = `${window.location.origin}/dashboard`

        const response = await manageSubscription(returnUrl)

        if (response?.data) {
            window.location.href = response?.data?.portal_session
        }
    }

    useEffect(() => {
        if (subscription) {
            if (subscription.plan === 'Free') {
                const result = trialPeriodEndDate(subscription.trial_end)

                setPlanName('Free')
                setPlanPrice(0)
                setTrialDaysLeft(result)
            } else if (subscription.plan.includes('Starter')) {
                setPlanPrice(9.99)
                setPlanName('Starter plan')
            } else if (subscription.plan.includes('Advanced')) {
                setPlanPrice(14.99)
                setPlanName('Advanced plan')
            } else if (subscription.plan.includes('Professional')) {
                setPlanPrice(45.99)
                setPlanName('Professional plan')
            } else if (subscription.plan.includes('Limited')) {
                setPlanPrice(1.45)
                setPlanName('14-day Limited Access plan')
            } else if (subscription.plan.includes('Full')) {
                setPlanPrice(1.95)
                setPlanName('14-day Full Access plan')
            } else if (subscription.plan.includes('Yearly')) {
                setPlanPrice(14.95)
                setPlanName('Yearly plan')
            } else if (subscription.plan.includes('Unlimited')) {
                setPlanPrice(29.00)
                setPlanName('Unlimited plan')
            }
        }
    }, [subscription])

    return (
        <Modal
            show={show}
            size="xl"
            popup="false"
            position='center'
            onClose={onClose}
            className='modal-please-signup manage-plan-modal'
        >
            <Modal.Body className='manage-plan-modal-body pt-20 md:pt-4'>
                <div className='reset-header-logo-mobile'>
                    <div onClick={onClose} className='flex items-center me-2 md:me-5'>
                        <img src={newLogo} alt="" />
                        <span className='header-logo-text'>QR Code Developer</span>
                    </div>
                </div>
                <div className='manage-plan-header'>
                    <span className='manage-plan-header-text'>Manage Plan</span>
                    <img onClick={onClose} className='manage-plan-header-close' src={managePlanCloseSvg} alt="" />
                </div>
                <div className='manage-plan-current-text-block'>
                    <span className='manage-plan-current-text'>Current Plan</span>
                    {trialDaysLeft <= 0 ? (
                        <div className='manage-plan-current-status-block-inactive'>
                            <span className='manage-plan-current-status-icon-inactive'></span>
                            Inactive
                        </div>
                    ) : (
                        <div className='manage-plan-current-status-block'>
                            <span className='manage-plan-current-status-icon'></span>
                            Active
                        </div>
                    )}
                </div>
                <div className='manage-plan-active-plan-block'>
                    <div className='manage-plan-active-plan-info'>
                        <span className='manage-plan-active-plan-name'>{planName}</span>
                        <span className='manage-plan-active-plan-price'>${planPrice}</span>
                    </div>
                    {subscription?.plan === 'Free' ? (
                        <button onClick={handleNavigateToUpgradePlan} className='manage-plan-active-plan-button'>Upgrade Plan</button>
                    ) : (
                        <>
                            {subscription?.payment_method !== 'paypal' && (
                                <button onClick={handleManagePlan} className='manage-plan-active-plan-button'>Manage Plan</button>
                            )}
                        </>
                    )}
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ManagePlanModal