import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import overviewPanelMobileBack from '../../assets/icons/overview-panel-mobile-back.svg'

const PrivicyPolicy = ({ fromSettings, setActiveTab, isMobile }) => {
    const user = useSelector((state) => state.app.user)

    const { t } = useTranslation()

    const handleNavigateBackToMenu = () => {
        if (isMobile) {
            setActiveTab('')
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            {fromSettings ? (
                <section className="product-information-section">
                    {isMobile && (
                        <div className='settings-mobile-tab-header pb-2 pt-3 mb-4'>
                            <h5 onClick={handleNavigateBackToMenu} className='settings-tab-title'>
                                {isMobile && (
                                    <img width={20} src={overviewPanelMobileBack} alt="" />
                                )}
                                {t("privacyPolicy")}
                                {isMobile && (
                                    <div style={{ width: '20px' }} />
                                )}
                            </h5>
                        </div>
                    )}
                    <h1 className="mb-4 text-2xl font-extrabold">{t("privacyPolicy")}</h1>
                    <p>{t("lastUpdatedAugust31")}</p>
                    <p>
                        {t("thisPrivacyPolicyDescribesOurPoliciesAndProcedures")}
                    </p>
                    <p>
                        {t("weUseYourPersonalDataToProvideAndImproveTheService")}
                    </p>
                    <h2 className="my-4 text-xl font-bold">
                        {t("interpretationAndDefinitions")}
                    </h2>
                    <h2 className="my-3 font-semibold text-xl">{t("interpretation")}</h2>
                    <p>
                        {t("theWordsOfWhichTheInitialLetterIsCapitalizedHaveMeanings")}
                    </p>
                    <h2 className="my-3 font-semibold text-xl">{t("definitions")}</h2>
                    <p>{t("forThePurposesOfThisPrivacyPolicy")}</p>
                    <ul className="mb-4 list-disc">
                        <li>
                            <p>
                                <strong>{t("account")}</strong> {t("meansAUniqueAccountCreatedForYouToAccess")}
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>{t("company")}</strong> {t("referredToAsEitherTheCompanyWeUsOrOurInThisAgreement")}
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>{t("cookies")}</strong> {t("areSmallFilesThatArePlacedOnYourComputer")}
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>{t("device")}</strong> {t("meansAnyDeviceThatCanAccessTheServiceSuchAsComputer")}
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>{t("personalData")}</strong> {t("isAnyInformationThatRelatesToAnIdentifiedOrIdentifiableIndividual")}
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>{t("service")}</strong> {t("refersToTheWebsite")}
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>{t("serviceProvider")}</strong> {t("meansAnyNaturalOrLegalPersonWhoProcessesTheData")}
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>{t("thirdPartySocialMedialService")}</strong> {t("refersToAnyWebsiteOrAnySocialNetworkWebsiteThroughWhichAUser")}
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>{t("usageData")}</strong> {t("refersToDataCollectedAutomatically")}
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>{t("website")}</strong> {t("refersToQRCodeveloperAccessibleFrom")}{" "}
                                <a
                                    href="https://qrcodeveloper.com"
                                    rel="external nofollow noopener noreferrer"
                                    target="_blank"
                                >
                                    https://qrcodeveloper.com
                                </a>
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>{t("you")}</strong> {t("meansTheIndividualAccessingOrUsingTheService")}
                            </p>
                        </li>
                    </ul>
                    <h2 className="my-4 text-xl font-bold">
                        {t("collectingAndUsingYourPersonalData")}
                    </h2>
                    <h2 className="my-3 font-semibold text-xl">{t("typesOfDataCollected")}</h2>
                    <h3>{t("personalData")}</h3>
                    <p>
                        {t("whileUsingOurServiceWeMayAskYouToProvideUsWithCertainPersonally")}
                    </p>
                    <ul className="mb-4 list-disc">
                        <li>
                            <p>{t("emailAddressLowercase")}</p>
                        </li>
                        <li>
                            <p>{t("usageData")}</p>
                        </li>
                    </ul>
                    <h3>{t("usageData")}</h3>
                    <p>{t("usageDataIsCollectedAutomaticallyWhenUsingTheService")}</p>
                    <p>
                        {t("usageDataMayIncludeInformationSuchAsDevicesInternetProtocolAddress")}
                    </p>
                    <p>
                        {t("whenYouAccessTheServiceByOrThroughAMobileDevice")}
                    </p>
                    <p>
                        {t("weMayAlsoCollectInformationThatYourBrowser")}
                    </p>
                    <h3>{t("informationFromThirdPartySocialMediaServices")}</h3>
                    <p>
                        {t("theCompanyAllowsYouToCreateAnAccountAndLogIn")}
                    </p>
                    <ul className="mb-4 list-disc">
                        <li>Google</li>
                        <li>Facebook</li>
                        <li>Twitter</li>
                        <li>LinkedIn</li>
                    </ul>
                    <p>
                        {t("ifYouDecideToRegisterThroughOrOtherwiseGrantUsAccess")}
                    </p>
                    <p>
                        {t("youMayAlsoHaveTheOptionOfSharingAdditionalInformation")}
                    </p>
                    <h3>{t("trackingTechnologiesAndCookies")}</h3>
                    <p>
                        {t("weUseCookiesAndSimilarTrackingTechnologiesToTrackTheActivity")}
                    </p>
                    <ul className="mb-4 list-disc">
                        <li>
                            <strong>{t("cookiesOrBrowserCookies")}</strong> {t("aCookieIsASmallFilePlacedOnYourDevice")}
                        </li>
                        <li>
                            <strong>{t("webBeacons")}</strong> {t("certainSectionsOfOurServiceAndOurEmailsMayContainsSmallElectronicFiles")}
                        </li>
                    </ul>
                    <p>
                        {t("cookiesCanBePersistentOrSession")}
                    </p>
                    <p>
                        {t("weUseBothSessionAndPersistentCookies")}
                    </p>
                    <ul className="mb-4 list-disc">
                        <li>
                            <p>
                                <strong>{t("necessaryEssentialCookies")}</strong>
                            </p>
                            <p>{t("typeSessionCookies")}</p>
                            <p>{t("administeredByUs")}</p>
                            <p>
                                {t("purposeTheseCookiesAreEssentialToProvideYouWithServices")}
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>{t("cookiesPolicyNoticeAcceptanceCookies")}</strong>
                            </p>
                            <p>{t("typePersistentCookies")}</p>
                            <p>{t("administeredByUs")}</p>
                            <p>
                                {t("purposeTheseCookiesIdentifyIfUsersHaveAccepted")}
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>{t("functionalityCookies")}</strong>
                            </p>
                            <p>{t("typePersistentCookies")}</p>
                            <p>{t("administeredByUs")}</p>
                            <p>
                                {t("purposeTheseCookiesAllowUsToRememberChoicesYouMakeWheYouUseTheWebsite")}
                            </p>
                        </li>
                    </ul>
                    <p>
                        {t("forMoreInformationAboutTheCookiesWeUseAndYourChoice")}
                    </p>
                    <h2 className="my-3 font-semibold text-xl">{t("useOfYourPersonalData")}</h2>
                    <p>{t("theCompanyMayUsePersonalDataForTheFollowingPurposes")}</p>
                    <ul className="mb-4 list-disc">
                        <li>
                            <p>
                                <strong>{t("toProvideAndMaintainOurService")}</strong>{t("includingToMonitorTheUsageOfOurService")}
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>{t("toManageYourAccount")}</strong> {t("toManageYourRegistrationAsAUserOfTheService")}
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>{t("forThePerformanceOfAContract")}</strong> {t("theDevelopmentComplianceAndUndertakingOfThePurchase")}
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>{t("toContactYou")}</strong> {t("toContactYouByEmailTelephoneCallsSms")}
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>{t("toProvideYou")}</strong> {t("withNewsSpecialOffersAndGeneralInformation")}
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>{t("toManageYourRequests")}</strong> {t("toAttendAndManageYOurRequestsToUs")}
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>{t("forBusinessTransfers")}</strong> {t("weMayUserYourInformationToEvaluateOrConductAMerger")}
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>{t("forOtherPurposes")}</strong> {t("weMayUseYourInformationForOtherPurposes")}
                            </p>
                        </li>
                    </ul>
                    <p>
                        {t("weMayShareYourPersonalInformationInTheFollowingSituations")}
                    </p>
                    <ul className="mb-4 list-disc">
                        <li>
                            <strong>{t("withServiceProviders")}</strong> {t("weMayShareYourPersonalInformation")}
                        </li>
                        <li>
                            <strong>{t("forBusinessTransfers")}</strong> {t("weMayShareOrTransferYourPersonalInformationInConnectionWith")}
                        </li>
                        <li>
                            <strong>{t("withAffiliates")}</strong> {t("weMayShareYourInformationWithOurAffiliates")}
                        </li>
                        <li>
                            <strong>{t("withBusinessPartners")}</strong> {t("weMayShareYourInformationWithPurBusinessPartners")}
                        </li>
                        <li>
                            <strong>{t("withOtherUsers")}</strong> {t("whenYouSharePersonalInformationOrOtherwiseInteractInThePublic")}
                        </li>
                        <li>
                            <strong>{t("withYourConsent")}</strong> {t("weMayDiscloseYourPersonalInformationForAnyOtherPurposesWithYourConsent")}
                        </li>
                    </ul>
                    <h2 className="my-3 font-semibold text-xl">{t("retentionOfYourPersonalData")}</h2>
                    <p>
                        {t("theCompanyWillRetainYourPersonalDataOnlyForAsLongAsIsNecessary")}
                    </p>
                    <p>
                        {t("theCompanyWillAlsoRetainUsageDataForInternalAnalysis")}
                    </p>
                    <h2 className="my-3 font-semibold text-xl">{t("transferOfYourPersonalData")}</h2>
                    <p>
                        {t("yourInformationIncludingPersonalDataIsProcessed")}
                    </p>
                    <p>
                        {t("yourConsentToThisPrivacyPolicy")}
                    </p>
                    <p>
                        {t("theCompanyWillTakeAllStepsReasonablyNecessaryToEnsure")}
                    </p>
                    <h2 className="my-3 font-semibold text-xl">{t("deleteYourPersonalData")}</h2>
                    <p>
                        {t("youHaveTheRightToDeleteOrRequestThatWeAssistInDeleting")}
                    </p>
                    <p>
                        {t("ourServiceMayGiveYouTheAbilityToDeleteCertainInformation")}
                    </p>
                    <p>
                        {t("youMayUpdateAmendOrDeleteYourInformationAtAnyTime")}
                    </p>
                    <p>
                        {t("pleaseNotHoweverThatWeMayNeedToRetainCertainInformation")}
                    </p>
                    <h2 className="my-3 font-semibold text-xl">{t("disclosureOfYourPersonalData")}</h2>
                    <h3>{t("businessTransactions")}</h3>
                    <p>
                        {t("ifTheCompanyIsInvolvedInAMergerAcquisitionOrAssetSale")}
                    </p>
                    <h3>{t("lawEnforcement")}</h3>
                    <p>
                        {t("underCertainCircumstancesTheCompanyMayBeRequired")}
                    </p>
                    <h3>{t("otherLegalRequirements")}</h3>
                    <p>
                        {t("theCompanyMayDiscloseYourPersonalDataInTheGood")}
                    </p>
                    <ul className="mb-4 list-disc">
                        <li>{t("complyWithALegalObligation")}</li>
                        <li>{t("protectAndDefendTheRightsOrProperty")}</li>
                        <li>
                            {t("preventOrInvestigatePossibleWrongdoing")}
                        </li>
                        <li>
                            {t("protectThePersonalSafetyOfUsersOfTheService")}
                        </li>
                        <li>{t("protectAgainstLegalLiability")}</li>
                    </ul>
                    <h2 className="my-3 font-semibold text-xl">{t("securityOfYourPersonalData")}</h2>
                    <p>
                        {t("theSecurityOfYourPersonalDataIsImportantToUs")}
                    </p>
                    <h2 className="my-4 text-xl font-bold">{t("childrenPrivacy")}</h2>
                    <p>
                        {t("ourServiceDoesNotAddressAnyoneUnderTheAgeOf13")}
                    </p>
                    <p>
                        {t("ifWeNeedToRelyOnConsentAsLegalBasis")}
                    </p>
                    <h2 className="my-4 text-xl font-bold">{t("linksToOtherWebsites")}</h2>
                    <p>
                        {t("ourServiceMayContainLinksToOtherWebsites")}
                    </p>
                    <p>
                        {t("weHaveNoControlOverAndAssumeNoResponsibilityForTheContent")}
                    </p>
                    <h2 className="my-4 text-xl font-bold">
                        {t("changesToThisPrivacyPolicy")}
                    </h2>
                    <p>
                        {t("weMayUpdateOurPrivacyPolicyFromTimeToTime")}
                    </p>
                    <p>
                        {t("weWillLetYouKnowViaEmailAndOrAProminentNotice")}
                    </p>
                    <p>
                        {t("youAreAdvisedToReviewThisPrivacyPolicyPeriodically")}
                    </p>
                    <h2 className="my-4 text-xl font-bold">{t("contactUs")}</h2>
                    <p>
                        {t("ifYouHaveAnyQuestionsAboutThisPrivacyPolicyYouCanContactUs")}
                    </p>
                    <ul className="mb-4 list-disc">
                        <li>{t("byEmail")} support@qrcodeveloper.com</li>
                    </ul>
                </section>
            ) : (
                <div className={`purple-corners-block-wrapper ${user ? '' : 'no-user'}`}>
                    <main className="product-information-main">
                        <section className="product-information-section">
                            <h1 className="mb-4 text-2xl font-extrabold">{t("privacyPolicy")}</h1>
                            <p>{t("lastUpdatedAugust31")}</p>
                            <p>
                                {t("thisPrivacyPolicyDescribesOurPoliciesAndProcedures")}
                            </p>
                            <p>
                                {t("weUseYourPersonalDataToProvideAndImproveTheService")}
                            </p>
                            <h2 className="my-4 text-xl font-bold">
                                {t("interpretationAndDefinitions")}
                            </h2>
                            <h2 className="my-3 font-semibold text-xl">{t("interpretation")}</h2>
                            <p>
                                {t("theWordsOfWhichTheInitialLetterIsCapitalizedHaveMeanings")}
                            </p>
                            <h2 className="my-3 font-semibold text-xl">{t("definitions")}</h2>
                            <p>{t("forThePurposesOfThisPrivacyPolicy")}</p>
                            <ul className="mb-4 list-disc">
                                <li>
                                    <p>
                                        <strong>{t("account")}</strong> {t("meansAUniqueAccountCreatedForYouToAccess")}
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong>{t("company")}</strong> {t("referredToAsEitherTheCompanyWeUsOrOurInThisAgreement")}
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong>{t("cookies")}</strong> {t("areSmallFilesThatArePlacedOnYourComputer")}
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong>{t("device")}</strong> {t("meansAnyDeviceThatCanAccessTheServiceSuchAsComputer")}
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong>{t("personalData")}</strong> {t("isAnyInformationThatRelatesToAnIdentifiedOrIdentifiableIndividual")}
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong>{t("service")}</strong> {t("refersToTheWebsite")}
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong>{t("serviceProvider")}</strong> {t("meansAnyNaturalOrLegalPersonWhoProcessesTheData")}
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong>{t("thirdPartySocialMedialService")}</strong> {t("refersToAnyWebsiteOrAnySocialNetworkWebsiteThroughWhichAUser")}
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong>{t("usageData")}</strong> {t("refersToDataCollectedAutomatically")}
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong>{t("website")}</strong> {t("refersToQRCodeveloperAccessibleFrom")}{" "}
                                        <a
                                            href="https://qrcodeveloper.com"
                                            rel="external nofollow noopener noreferrer"
                                            target="_blank"
                                        >
                                            https://qrcodeveloper.com
                                        </a>
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong>{t("you")}</strong> {t("meansTheIndividualAccessingOrUsingTheService")}
                                    </p>
                                </li>
                            </ul>
                            <h2 className="my-4 text-xl font-bold">
                                {t("collectingAndUsingYourPersonalData")}
                            </h2>
                            <h2 className="my-3 font-semibold text-xl">{t("typesOfDataCollected")}</h2>
                            <h3>{t("personalData")}</h3>
                            <p>
                                {t("whileUsingOurServiceWeMayAskYouToProvideUsWithCertainPersonally")}
                            </p>
                            <ul className="mb-4 list-disc">
                                <li>
                                    <p>{t("emailAddressLowercase")}</p>
                                </li>
                                <li>
                                    <p>{t("usageData")}</p>
                                </li>
                            </ul>
                            <h3>{t("usageData")}</h3>
                            <p>{t("usageDataIsCollectedAutomaticallyWhenUsingTheService")}</p>
                            <p>
                                {t("usageDataMayIncludeInformationSuchAsDevicesInternetProtocolAddress")}
                            </p>
                            <p>
                                {t("whenYouAccessTheServiceByOrThroughAMobileDevice")}
                            </p>
                            <p>
                                {t("weMayAlsoCollectInformationThatYourBrowser")}
                            </p>
                            <h3>{t("informationFromThirdPartySocialMediaServices")}</h3>
                            <p>
                                {t("theCompanyAllowsYouToCreateAnAccountAndLogIn")}
                            </p>
                            <ul className="mb-4 list-disc">
                                <li>Google</li>
                                <li>Facebook</li>
                                <li>Twitter</li>
                                <li>LinkedIn</li>
                            </ul>
                            <p>
                                {t("ifYouDecideToRegisterThroughOrOtherwiseGrantUsAccess")}
                            </p>
                            <p>
                                {t("youMayAlsoHaveTheOptionOfSharingAdditionalInformation")}
                            </p>
                            <h3>{t("trackingTechnologiesAndCookies")}</h3>
                            <p>
                                {t("weUseCookiesAndSimilarTrackingTechnologiesToTrackTheActivity")}
                            </p>
                            <ul className="mb-4 list-disc">
                                <li>
                                    <strong>{t("cookiesOrBrowserCookies")}</strong> {t("aCookieIsASmallFilePlacedOnYourDevice")}
                                </li>
                                <li>
                                    <strong>{t("webBeacons")}</strong> {t("certainSectionsOfOurServiceAndOurEmailsMayContainsSmallElectronicFiles")}
                                </li>
                            </ul>
                            <p>
                                {t("cookiesCanBePersistentOrSession")}
                            </p>
                            <p>
                                {t("weUseBothSessionAndPersistentCookies")}
                            </p>
                            <ul className="mb-4 list-disc">
                                <li>
                                    <p>
                                        <strong>{t("necessaryEssentialCookies")}</strong>
                                    </p>
                                    <p>{t("typeSessionCookies")}</p>
                                    <p>{t("administeredByUs")}</p>
                                    <p>
                                        {t("purposeTheseCookiesAreEssentialToProvideYouWithServices")}
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong>{t("cookiesPolicyNoticeAcceptanceCookies")}</strong>
                                    </p>
                                    <p>{t("typePersistentCookies")}</p>
                                    <p>{t("administeredByUs")}</p>
                                    <p>
                                        {t("purposeTheseCookiesIdentifyIfUsersHaveAccepted")}
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong>{t("functionalityCookies")}</strong>
                                    </p>
                                    <p>{t("typePersistentCookies")}</p>
                                    <p>{t("administeredByUs")}</p>
                                    <p>
                                        {t("purposeTheseCookiesAllowUsToRememberChoicesYouMakeWheYouUseTheWebsite")}
                                    </p>
                                </li>
                            </ul>
                            <p>
                                {t("forMoreInformationAboutTheCookiesWeUseAndYourChoice")}
                            </p>
                            <h2 className="my-3 font-semibold text-xl">{t("useOfYourPersonalData")}</h2>
                            <p>{t("theCompanyMayUsePersonalDataForTheFollowingPurposes")}</p>
                            <ul className="mb-4 list-disc">
                                <li>
                                    <p>
                                        <strong>{t("toProvideAndMaintainOurService")}</strong>{t("includingToMonitorTheUsageOfOurService")}
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong>{t("toManageYourAccount")}</strong> {t("toManageYourRegistrationAsAUserOfTheService")}
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong>{t("forThePerformanceOfAContract")}</strong> {t("theDevelopmentComplianceAndUndertakingOfThePurchase")}
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong>{t("toContactYou")}</strong> {t("toContactYouByEmailTelephoneCallsSms")}
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong>{t("toProvideYou")}</strong> {t("withNewsSpecialOffersAndGeneralInformation")}
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong>{t("toManageYourRequests")}</strong> {t("toAttendAndManageYOurRequestsToUs")}
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong>{t("forBusinessTransfers")}</strong> {t("weMayUserYourInformationToEvaluateOrConductAMerger")}
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong>{t("forOtherPurposes")}</strong> {t("weMayUseYourInformationForOtherPurposes")}
                                    </p>
                                </li>
                            </ul>
                            <p>
                                {t("weMayShareYourPersonalInformationInTheFollowingSituations")}
                            </p>
                            <ul className="mb-4 list-disc">
                                <li>
                                    <strong>{t("withServiceProviders")}</strong> {t("weMayShareYourPersonalInformation")}
                                </li>
                                <li>
                                    <strong>{t("forBusinessTransfers")}</strong> {t("weMayShareOrTransferYourPersonalInformationInConnectionWith")}
                                </li>
                                <li>
                                    <strong>{t("withAffiliates")}</strong> {t("weMayShareYourInformationWithOurAffiliates")}
                                </li>
                                <li>
                                    <strong>{t("withBusinessPartners")}</strong> {t("weMayShareYourInformationWithPurBusinessPartners")}
                                </li>
                                <li>
                                    <strong>{t("withOtherUsers")}</strong> {t("whenYouSharePersonalInformationOrOtherwiseInteractInThePublic")}
                                </li>
                                <li>
                                    <strong>{t("withYourConsent")}</strong> {t("weMayDiscloseYourPersonalInformationForAnyOtherPurposesWithYourConsent")}
                                </li>
                            </ul>
                            <h2 className="my-3 font-semibold text-xl">{t("retentionOfYourPersonalData")}</h2>
                            <p>
                                {t("theCompanyWillRetainYourPersonalDataOnlyForAsLongAsIsNecessary")}
                            </p>
                            <p>
                                {t("theCompanyWillAlsoRetainUsageDataForInternalAnalysis")}
                            </p>
                            <h2 className="my-3 font-semibold text-xl">{t("transferOfYourPersonalData")}</h2>
                            <p>
                                {t("yourInformationIncludingPersonalDataIsProcessed")}
                            </p>
                            <p>
                                {t("yourConsentToThisPrivacyPolicy")}
                            </p>
                            <p>
                                {t("theCompanyWillTakeAllStepsReasonablyNecessaryToEnsure")}
                            </p>
                            <h2 className="my-3 font-semibold text-xl">{t("deleteYourPersonalData")}</h2>
                            <p>
                                {t("youHaveTheRightToDeleteOrRequestThatWeAssistInDeleting")}
                            </p>
                            <p>
                                {t("ourServiceMayGiveYouTheAbilityToDeleteCertainInformation")}
                            </p>
                            <p>
                                {t("youMayUpdateAmendOrDeleteYourInformationAtAnyTime")}
                            </p>
                            <p>
                                {t("pleaseNotHoweverThatWeMayNeedToRetainCertainInformation")}
                            </p>
                            <h2 className="my-3 font-semibold text-xl">{t("disclosureOfYourPersonalData")}</h2>
                            <h3>{t("businessTransactions")}</h3>
                            <p>
                                {t("ifTheCompanyIsInvolvedInAMergerAcquisitionOrAssetSale")}
                            </p>
                            <h3>{t("lawEnforcement")}</h3>
                            <p>
                                {t("underCertainCircumstancesTheCompanyMayBeRequired")}
                            </p>
                            <h3>{t("otherLegalRequirements")}</h3>
                            <p>
                                {t("theCompanyMayDiscloseYourPersonalDataInTheGood")}
                            </p>
                            <ul className="mb-4 list-disc">
                                <li>{t("complyWithALegalObligation")}</li>
                                <li>{t("protectAndDefendTheRightsOrProperty")}</li>
                                <li>
                                    {t("preventOrInvestigatePossibleWrongdoing")}
                                </li>
                                <li>
                                    {t("protectThePersonalSafetyOfUsersOfTheService")}
                                </li>
                                <li>{t("protectAgainstLegalLiability")}</li>
                            </ul>
                            <h2 className="my-3 font-semibold text-xl">{t("securityOfYourPersonalData")}</h2>
                            <p>
                                {t("theSecurityOfYourPersonalDataIsImportantToUs")}
                            </p>
                            <h2 className="my-4 text-xl font-bold">{t("childrenPrivacy")}</h2>
                            <p>
                                {t("ourServiceDoesNotAddressAnyoneUnderTheAgeOf13")}
                            </p>
                            <p>
                                {t("ifWeNeedToRelyOnConsentAsLegalBasis")}
                            </p>
                            <h2 className="my-4 text-xl font-bold">{t("linksToOtherWebsites")}</h2>
                            <p>
                                {t("ourServiceMayContainLinksToOtherWebsites")}
                            </p>
                            <p>
                                {t("weHaveNoControlOverAndAssumeNoResponsibilityForTheContent")}
                            </p>
                            <h2 className="my-4 text-xl font-bold">
                                {t("changesToThisPrivacyPolicy")}
                            </h2>
                            <p>
                                {t("weMayUpdateOurPrivacyPolicyFromTimeToTime")}
                            </p>
                            <p>
                                {t("weWillLetYouKnowViaEmailAndOrAProminentNotice")}
                            </p>
                            <p>
                                {t("youAreAdvisedToReviewThisPrivacyPolicyPeriodically")}
                            </p>
                            <h2 className="my-4 text-xl font-bold">{t("contactUs")}</h2>
                            <p>
                                {t("ifYouHaveAnyQuestionsAboutThisPrivacyPolicyYouCanContactUs")}
                            </p>
                            <ul className="mb-4 list-disc">
                                <li>{t("byEmail")} support@qrcodeveloper.com</li>
                            </ul>
                        </section>
                    </main>
                </div>
            )}
        </>
    )
}

export default PrivicyPolicy