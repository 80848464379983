import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

const CancelPlanConfirmationMobile = ({ setShowCancelPlanModal, cancelPlan, blockRef }) => {
    const subscription = useSelector((state) => state.app.subscription)

    const { t } = useTranslation()

    const handleCancelPlan = () => {
        cancelPlan()
        window.gtag('event', 'user_cancel_plan_from_settings', {
            payment_method: subscription?.payment_method === 'paypal' ? 'paypal' : 'stripe',
        })
        setShowCancelPlanModal(false)
    }

    return (
        <div style={{ zIndex: '101' }} className='link-filter-background analytics-mobile-settings'>
            <div ref={blockRef} className='link-filter-block'>
                <div onClick={() => setShowCancelPlanModal(false)} className='mobile-tray-horizontal-bar'>
                    <div className='mobile-tray-horizontal-bar-element'></div>
                </div>
                <h5 className='confrim-modal-title w-full mb-3'>{t("areYouSureYouWantToCancelYourPlan")}</h5>
                <div className='confirm-modal-actions-block flex-col-reverse'>
                    <button onClick={() => setShowCancelPlanModal(false)} className='confirm-modal-action-cancel'>{t("keepPlan")}</button>
                    <button onClick={handleCancelPlan} className='confirm-modal-action-delete'>{t("yesCancelPlan")}</button>
                </div>
            </div>
        </div>
    )
}

export default CancelPlanConfirmationMobile