import { updateProfile, updatePassword, updateEmail } from 'firebase/auth'
import React, { useRef, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FiEye, FiEyeOff } from 'react-icons/fi'
import { useTranslation } from 'react-i18next'

import {
    FULL_VERSION_TO_SHORT_VERSION_OF_LANGUAGE,
    LANGUAGE_NAME_TO_KEY_TRANSLATIONS,
    SHORT_VERSION_TO_FULL_VERSION_OF_LANGUAGE
} from '../../helpers/translation-constants'
import {
    useUpdateProfileImageMutation,
    useUpdateUserSettingsMutation
} from '../../api/api'
import { fileToBlob } from '../../helpers/functions'
import { setUser } from '../../redux/appSlice'
import useToasts from '../../hooks/useToasts'
import { auth } from '../../firebase/config'

import AccountDeletionFeedbackModal from '../AccountDeletionFeedbackModal/AccountDeletionFeedbackModal'
import ProfileMobileSelectLanguage from '../ProfileMobileSelectLanguage/ProfileMobileSelectLanguage'
import ConfirmAccountDeletionModal from '../ConfirmAccountDeletionModal/ConfirmAccountDeletionModal'
import EnterPasswordModal from '../EnterPasswordModal/EnterPasswordModal'

import overviewPanelMobileBack from '../../assets/icons/overview-panel-mobile-back.svg'
import defaultAvatarJpg from '../../assets/images/default-avatar.jpg'
import SettingsGoogle from '../../assets/icons/settings/google.svg'
import SettingsSearch from '../../assets/icons/settings/search.svg'
import ArrowBottom from '../../assets/icons/arrowbottom.svg'

export const AVAILABLE_LANGUAGES_OPTIONS = [
    'English',
    'Portuguese',
    'German',
    'Spanish'
]

const ProfileSettings = ({ setActiveTab, isMobile }) => {
    const [showEnterPasswordModal, setShowEnterPasswordModal] = useState(false)
    const [showConfirmAccountDeletionModal, setShowConfirmAccountDeletionModal] = useState(false)
    const [showAccountDeletionFeedbackModal, setShowAccountDeletionFeedbackModal] = useState(false)
    const [filteredLanguages, setFilteredLanguages] = useState(AVAILABLE_LANGUAGES_OPTIONS)
    const [selectedLanguage, setSelectedLanguage] = useState('English')
    const [expandLanguages, setExpandLanguages] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [uploadedFile, setUploadedFile] = useState(null)
    const [password, setPassword] = useState("Password")
    const [searchValue, setSearchValue] = useState('')
    const [fullName, setFullName] = useState(null)
    const [isEdit, setIsEdit] = useState(false)
    const [email, setEmail] = useState('')
    const user = useSelector((state) => state.app.user)
    const settings = useSelector((state) => state.app.settings)
    const subscription = useSelector((state) => state.app.subscription)

    const isDisabledButton = uploadedFile || user.displayName !== fullName || password !== 'Password' || email !== user?.email || settings?.language !== FULL_VERSION_TO_SHORT_VERSION_OF_LANGUAGE[selectedLanguage] ? false : true

    const { t } = useTranslation()

    const dispatch = useDispatch()

    const fileInputRef = useRef(null)
    const emailInputRef = useRef(null)
    const mobileSelectLanguageRef = useRef(null)

    const { profileInformationUpdatedToast } = useToasts()

    const [updateImage] = useUpdateProfileImageMutation()
    const [updateUserSettings] = useUpdateUserSettingsMutation()

    const handleChangeFile = (e) => {
        setUploadedFile(e.target.files[0])
    }

    const handleUploadFile = () => {
        fileInputRef.current.click()
    }

    const handleCancelChanges = () => {
        setFullName(user.displayName)
        setPassword('Password')
        setUploadedFile(null)
        setIsEdit(false)
    }

    const handleNavigateBackToMenu = () => {
        if (isMobile) {
            setActiveTab('')
        }
    }

    const handleChangeLanguage = (language) => {
        setSelectedLanguage(language)
    }

    const handleSaveChanges = async () => {
        if (uploadedFile) {
            const convertedImageToBlob = await fileToBlob(uploadedFile)

            const formData = new FormData()
            formData.append('image', convertedImageToBlob)

            updateImage(formData)
            setUploadedFile(null)
            setIsEdit(false)
        }

        if (user.displayName !== fullName) {
            updateProfile(auth.currentUser, {
                displayName: fullName,
            }).then(() => {
                const currentUser = auth.currentUser

                dispatch(setUser({ user: currentUser }))
                setActiveTab('')
                setTimeout(() => {
                    setActiveTab('Profile')
                }, 5)
            })
        }

        if (settings?.language !== FULL_VERSION_TO_SHORT_VERSION_OF_LANGUAGE[selectedLanguage]) {
            const language = FULL_VERSION_TO_SHORT_VERSION_OF_LANGUAGE[selectedLanguage]

            updateUserSettings({
                data: {
                    language
                }
            }).then(() => {
                setIsEdit(false)
            })
        }

        if (password !== 'Password') {
            await updatePassword(auth.currentUser, password)
        }

        if (user.email !== email) {
            await updateEmail(auth.currentUser, email)
        }

        if (email === user.email && password === 'Password') {
            profileInformationUpdatedToast()
        }
    }

    const handleDeleteAccount = () => {
        setShowConfirmAccountDeletionModal(true)
    }

    const handleUpdateProfileInfo = () => {
        if (user.email !== email || password !== 'Password') {
            setShowEnterPasswordModal(true)
        } else {
            handleSaveChanges()
        }
    }

    useEffect(() => {
        if (settings) {
            const language = SHORT_VERSION_TO_FULL_VERSION_OF_LANGUAGE[settings.language] ?? 'English'

            setSelectedLanguage(language)
        }
    }, [settings])

    useEffect(() => {
        if (emailInputRef.current) {
            if (!isMobile) {
                emailInputRef.current.parentElement.style.minWidth = `${emailInputRef.current.scrollWidth + 60}px`
            } else {
                emailInputRef.current.parentElement.style.minWidth = `unset`
            }
        }
    }, [email, isEdit, isMobile])

    useEffect(() => {
        if (user) {
            setFullName(user.displayName)
            setEmail(user.email)
        }
    }, [user])

    useEffect(() => {
        setFilteredLanguages(AVAILABLE_LANGUAGES_OPTIONS.filter(language => language.toLowerCase().includes(searchValue.toLowerCase())))
    }, [searchValue])

    useEffect(() => {
        const handleClick = (e) => {
            if (mobileSelectLanguageRef.current && !mobileSelectLanguageRef.current.contains(e.target)) {
                setExpandLanguages(false)
            }
        }

        document.addEventListener('mousedown', handleClick)

        return () => {
            document.removeEventListener('mousedown', handleClick)
        }
    }, [])

    return (
        <div className='settings-tab-wrapper'>
            {isMobile ? (
                <div className='settings-mobile-tab-header'>
                    <h5 onClick={handleNavigateBackToMenu} className='settings-tab-title'>
                        {isMobile && (
                            <img width={20} src={overviewPanelMobileBack} alt="" />
                        )}
                        {t("profile")}
                        {isMobile && (
                            <div style={{ width: '20px' }} />
                        )}
                    </h5>
                    <span className='settings-tab-description'>
                        {t("updateYourNameEmailAndPreferredLanguage")}
                    </span>
                </div>
            ) : (
                <>
                    <h5 className='settings-tab-title'>
                        {t("profile")}
                    </h5>
                    <span className='settings-tab-description'>
                        {t("updateYourNameEmailAndPreferredLanguage")}
                    </span>
                </>
            )}
            {isEdit ? (
                <div className='settings-tab-information-block'>
                    <div className='settings-tab-user-information-block'>
                        <div className='user-information-profile-image-wrapper'>
                            {uploadedFile ? (
                                <img className='settings-tab-user-avatar' src={URL.createObjectURL(uploadedFile)} alt="" />
                            ) : (
                                <>
                                    {subscription?.profile_image ? (
                                        <img className='settings-tab-user-avatar' src={subscription.profile_image} alt="" />
                                    ) : (
                                        <img className='settings-tab-user-avatar' src={defaultAvatarJpg} alt="" />
                                    )}
                                </>
                            )}
                            <div onClick={handleUploadFile} className='user-information-profile-image-change-block'>{t("changePhoto")}</div>
                            <input ref={fileInputRef} onChange={handleChangeFile} type="file" className='hidden' />
                        </div>
                        <div className='settings-tab-user-profile-information'>
                            <div className={`profile-settings-input-wrapper ${!fullName?.length ? 'empty' : ''}`}>
                                <label className='profile-settings-input-label'>{t("fullName")}</label>
                                <input
                                    className='profile-settings-input'
                                    onChange={(e) => setFullName(e.target.value)}
                                    placeholder={t("fullName")}
                                    value={fullName ? fullName : ""}
                                />
                            </div>
                            <div className='settings-select-language-container'>
                                <div onClick={() => setExpandLanguages(!expandLanguages)} className={`profile-settings-input-wrapper cursor-pointer select-none ${expandLanguages ? 'account-deletion-reason-block-active' : ''}`}>
                                    <label className='profile-settings-input-label'>{t("language")}</label>
                                    <span className='profile-settings-input mb-[-3px]'>
                                        {t(`${LANGUAGE_NAME_TO_KEY_TRANSLATIONS[selectedLanguage]}`)}
                                    </span>
                                    <img className='absolute top-[50%] right-4' style={{ transform: `rotate(${expandLanguages ? '180deg' : '0deg'}) translateY(${expandLanguages ? '50%' : '-50%'})` }} src={ArrowBottom} alt="" />
                                </div>
                                {expandLanguages && !isMobile && (
                                    <div className='confirm-account-deletion-modal-reason-dropdown h-fit top-[58px]'>
                                        <div className='profile-settings-select-language-search-block mb-2'>
                                            <img src={SettingsSearch} alt="" />
                                            <input
                                                value={searchValue}
                                                onChange={(e) => setSearchValue(e.target.value)}
                                                placeholder={t("search")}
                                                className='profile-settings-select-language-search-input'
                                                type="text"
                                            />
                                        </div>
                                        {filteredLanguages.map((language, index) => (
                                            <div
                                                key={index}
                                                onClick={() => { handleChangeLanguage(language); setExpandLanguages(false) }}
                                                className='account-deletion-feedback-modal-reason'
                                            >
                                                {t(`${LANGUAGE_NAME_TO_KEY_TRANSLATIONS[language]}`)}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                            {user?.providerData[0]?.providerId === 'password' ? (
                                <div className='profile-settings-input-wrapper'>
                                    <label className='profile-settings-input-label'>{t("emailAddress")}</label>
                                    <input
                                        className='profile-settings-input'
                                        onChange={(e) => setEmail(e.target.value)}
                                        placeholder={t("emailAddress")}
                                        value={email}
                                        ref={emailInputRef}
                                    />
                                </div>
                            ) : (
                                <div className='profile-settings-input-wrapper'>
                                    <label className='profile-settings-input-label disabled'>{t("googleEmailAddress")}</label>
                                    <input
                                        className='profile-settings-input mr-6'
                                        onChange={(e) => setEmail(e.target.value)}
                                        placeholder={t("emailAddress")}
                                        value={email}
                                        readOnly={true}
                                        ref={emailInputRef}
                                    />
                                    <img className='profile-settings-input-google-image' src={SettingsGoogle} alt="" />
                                </div>
                            )}
                            {user?.providerData[0]?.providerId === 'password' && (
                                <div className='profile-settings-input-wrapper relative'>
                                    <label className='profile-settings-input-label'>{t("password")}</label>
                                    <input
                                        className='profile-settings-input'
                                        type={showPassword ? 'text' : 'password'}
                                        value={password}
                                        placeholder={t("password")}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                    {showPassword ? (
                                        <FiEye onClick={() => setShowPassword(false)} color={"#BEC5CF"} className='profile-settings-password-eye' size={20} />
                                    ) : (
                                        <FiEyeOff onClick={() => setShowPassword(true)} color={"#BEC5CF"} className='profile-settings-password-eye' size={20} />
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className='flex items-center gap-2 mobile-save-cancel-profile-buttons'>
                        <button
                            onClick={handleUpdateProfileInfo}
                            disabled={isDisabledButton}
                            className='settings-tab-user-information-edit-btn'
                        >
                            {t("save")}
                        </button>
                        <button
                            onClick={handleCancelChanges}
                            className='settings-tab-user-information-edit-cancel'
                        >
                            {t("cancel")}
                        </button>
                    </div>
                </div>
            ) : (
                <div className='settings-tab-information-block'>
                    <div className='settings-tab-user-information-block'>
                        {subscription?.profile_image ? (
                            <img className='settings-tab-user-avatar' src={subscription.profile_image} alt="" />
                        ) : (
                            <img className='settings-tab-user-avatar' src={defaultAvatarJpg} alt="" />
                        )}
                        <div className='settings-tab-user-profile-information'>
                            <div className='settings-tab-user-profile-information-item'>
                                <span className='settings-tab-user-profile-information-label'>{t("fullName")}</span>
                                <span className='settings-tab-user-profile-information-value'>{user.displayName ? user.displayName : user?.email?.split('@')[0]}</span>
                            </div>
                            <div className='settings-tab-user-profile-information-item'>
                                <span className='settings-tab-user-profile-information-label'>{t("language")}</span>
                                <span className='settings-tab-user-profile-information-value'>
                                    {t(`${LANGUAGE_NAME_TO_KEY_TRANSLATIONS[selectedLanguage]}`)}
                                </span>
                            </div>
                            {user?.providerData[0]?.providerId === 'password' ? (
                                <>
                                    <div className='settings-tab-user-profile-information-item'>
                                        <span className='settings-tab-user-profile-information-label'>{t("emailAddress")}</span>
                                        <span className='settings-tab-user-profile-information-value'>{user.email}</span>
                                    </div>
                                    <div className='settings-tab-user-profile-information-item'>
                                        <span className='settings-tab-user-profile-information-label'>{t("password")}</span>
                                        <span className='settings-tab-user-profile-information-value flex items-center gap-1 h-[24px]'>
                                            <span className='user-profile-information-value-password-placeholder-circle'></span>
                                            <span className='user-profile-information-value-password-placeholder-circle'></span>
                                            <span className='user-profile-information-value-password-placeholder-circle'></span>
                                            <span className='user-profile-information-value-password-placeholder-circle'></span>
                                            <span className='user-profile-information-value-password-placeholder-circle'></span>
                                            <span className='user-profile-information-value-password-placeholder-circle'></span>
                                            <span className='user-profile-information-value-password-placeholder-circle'></span>
                                        </span>
                                    </div>
                                </>
                            ) : (
                                <div className='settings-tab-user-profile-information-item'>
                                    <span className='settings-tab-user-profile-information-label'>{t("googleEmailAddress")}</span>
                                    <span className='settings-tab-user-profile-information-value'>{user.email}</span>
                                </div>
                            )}
                        </div>
                    </div>
                    <button onClick={() => setIsEdit(true)} className='settings-tab-user-information-edit-btn'>{t("editProfile")}</button>
                </div>
            )}
            <div className='settings-tab-delete-account'>
                <button onClick={handleDeleteAccount} className='profile-settings-delete-account-button'>{t("deleteAccount")}</button>
                <span className='profile-settings-delete-account-description'>
                    {t("deletingYourAccountIsAPermanentAction")}
                </span>
            </div>
            <EnterPasswordModal
                show={showEnterPasswordModal}
                handleSaveChanges={handleSaveChanges}
                onClose={() => setShowEnterPasswordModal(false)}
            />
            {expandLanguages && isMobile && (
                <ProfileMobileSelectLanguage
                    selectedLanguage={selectedLanguage}
                    mobileSelectLanguageRef={mobileSelectLanguageRef}
                    setExpandLanguages={setExpandLanguages}
                    setSelectedLanguage={setSelectedLanguage}
                />
            )}
            {showConfirmAccountDeletionModal && (
                <ConfirmAccountDeletionModal
                    show={showConfirmAccountDeletionModal}
                    onClose={() => setShowConfirmAccountDeletionModal(false)}
                    setShowAccountDeletionFeedbackModal={setShowAccountDeletionFeedbackModal}
                />
            )}
            {showAccountDeletionFeedbackModal && (
                <AccountDeletionFeedbackModal
                    show={showAccountDeletionFeedbackModal}
                    onClose={() => setShowAccountDeletionFeedbackModal(false)}
                />
            )}
        </div>
    )
}

export default ProfileSettings