import { useSearchParams } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { useCustomNavigate } from '../../hooks/useCustomNavigate'

import paymentErrorSvg from '../../assets/icons/payment-error.svg'
import newLogo from '../../assets/icons/newlogo.svg'

const CodeExpired = () => {
    const user = useSelector((state) => state.app.user)
    const subscription = useSelector((state) => state.app.subscription)
    const [isExpired, setIsExpired] = useState(false)
    const [searchParams] = useSearchParams()

    const { t } = useTranslation()

    const navigate = useCustomNavigate()

    const navigateToSite = () => {
        navigate('/')
    }

    const handleSignUp = async () => {
        if (user) {
            navigate('dashboard')
        } else {
            navigate('/sign-up?upgrade=true&ownerLogin=true')
        }
    }

    const handleSignIn = async () => {
        if (user) {
            if (subscription?.new_flow) {
                navigate('/upgrade-plan-new')
            } else {
                navigate('/upgrade-plan')
            }
        } else {
            navigate('/sign-in?ownerLogin=true')
        }
    }

    useEffect(() => {
        const status = searchParams.get('status')
        const code = searchParams.get('code')

        window.gtag('event', 'expired_code_by_id', {
            status: status === 'expired' ? 'expired' : 'archived',
            code_public_id: code
        })
    }, [searchParams])

    useEffect(() => {
        const status = searchParams.get('status')
        const redirect = searchParams.get('redirect')

        if (status) {
            setIsExpired(status === 'expired' ? true : false)
        }

        if (redirect && subscription) {
            if (redirect === 'upgrade' && isExpired) {
                if (subscription?.new_flow) {
                    navigate('/upgrade-plan-new')
                } else {
                    navigate('/upgrade-plan')
                }
            } else {
                navigate('/dashboard')
            }
        }
    }, [searchParams, subscription])

    return (
        <div className='code-expired-wrapper'>
            <header className='code-expired-header'>
                <div onClick={navigateToSite} className='flex items-center cursor-pointer'>
                    <img src={newLogo} alt="" />
                    <span className='header-logo-text'>QR Code Developer</span>
                </div>
            </header>
            <div className='code-expired-main-content'>
                <img className='upgrade-success-img' src={paymentErrorSvg} alt="" />
                <h2 className='code-expired-title'>{t("thisQRCodeHas")} {isExpired ? t("expired") : t("archived")}.</h2>
                <p className='code-expired-description'>{t("theOwnerNeedsTo")} {isExpired && t("upgradeTheirAccountTo")} {t("reActivateThisQRCode")}</p>
                <button onClick={handleSignUp} className='code-expired-create-button'>{t("createQRCode")}</button>
                <span className='code-expired-owner-text'>
                    {t("accountOwner")} <span onClick={handleSignIn} className='code-expired-owner-login-text'>{t("logInToActivate")}</span>
                </span>
                <div className='code-expired-mobile-create-block'>
                    <button onClick={handleSignUp} className='code-expired-create-button-mobile'>{t("createQRCode")}</button>
                </div>
            </div>
        </div>
    )
}

export default CodeExpired