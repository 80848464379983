import { useTranslation } from 'react-i18next'

const useConvertTimeToTextFormat = () => {
    const { t } = useTranslation()

    const convertTimeToTextFormat = (date) => {
        const time = new Date(date)
        const now = new Date()

        const timeDifference = now.getTime() - time.getTime();
        const seconds = Math.floor(timeDifference / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        const weeks = Math.floor(days / 7);
        const months = Math.floor(weeks / 4);
        const years = Math.floor(months / 12);

        let timeString = '';

        if (years > 0) {
            timeString = years === 1 ? t("1yearAgo") : `${years} ${t("yearsAgo")}`
        } else if (months > 0) {
            timeString = months === 1 ? t("1monthAgo") : `${months} ${t("monthsAgo")}`
        } else if (weeks > 0) {
            timeString = weeks === 1 ? t("1weekAgo") : `${weeks} ${t("weeksAgo")}`
        } else if (days > 0) {
            timeString = days === 1 ? t("1dayAgo") : `${days} ${t("daysAgo")}`
        } else if (hours > 0) {
            timeString = hours === 1 ? t("1hourAgo") : `${hours} ${t("hoursAgo")}`
        } else if (minutes > 0) {
            timeString = minutes === 1 ? t("1minuteAgo") : `${minutes} ${t("minutesAgo")}`
        } else {
            timeString = seconds === 1 ? t("1sAgo") : `${seconds}${t("sAgo")}`
        }

        return timeString
    }

    return { convertTimeToTextFormat }
}

export default useConvertTimeToTextFormat