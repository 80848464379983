import { useTranslation } from 'react-i18next'
import { Modal } from 'react-bootstrap'
import React from 'react'

import { useSendEmailSendgridCancelPlanMutation } from '../../api/api'

const CancelPlanConfirmationModal = ({ show, onClose, cancelPlan, planName }) => {
    const { t } = useTranslation()

    const [sendEmailSendgridCancelPlan] = useSendEmailSendgridCancelPlanMutation()

    const handleCancelPlan = () => {
        cancelPlan()
        onClose()
        sendEmailSendgridCancelPlan({
            plan_name: planName,
        })
    }

    return (
        <Modal
            show={show}
            size="md"
            popup="false"
            centered
            position='center'
            onClose={onClose}
            className='modal-please-signup'
        >
            <Modal.Body className='confirm-archive-modal-body'>
                <h5 className='confrim-modal-title'>{t("areYouSureYouWantToCancelYourPlan")}</h5>
                <div className='confirm-modal-actions-block'>
                    <button onClick={onClose} className='confirm-modal-action-cancel'>{t("keepPlan")}</button>
                    <button onClick={handleCancelPlan} className='confirm-modal-action-delete'>{t("yesCancelPlan")}</button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default CancelPlanConfirmationModal