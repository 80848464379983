import { IoIosArrowForward } from 'react-icons/io'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { USER_CREATE_CODE_IMAGES_TRANSLATIONS } from '../../helpers/image-translations'
import { useUserCodeCreationContext } from '../../context/UserQrCreationContext'
import { USER_QR_TYPE_TRANSLATIONS } from '../../helpers/translation-constants'
import useReturnCodeTypesData from '../../hooks/useReturnCodeTypesData'
import i18n from '../../i18n/config'

import DashboardCreationContent from '../DashboardCreationContent/DashboardCreationContent'
import EditDesignLandingPage from '../EditDesignLandingPage/EditDesignLandingPage'
import DashboardPreviewBlock from '../DashboardPreviewBlock/DashboardPreviewBlock'
import EditDesignQrCode from '../EditDesignQrCode/EditDesignQrCode'

import CodeCreateBack from '../../assets/icons/code-create/back.svg'
import CodeCreateEdit from '../../assets/icons/code-create/edit.svg'
import qrExampleSvg from '../../assets/icons/qrExample.svg'

const UserCreateCode = ({ handleTriggerAndGenerate, triggerValidation, setIsValid, isValid }) => {
    const [step, setStep] = useState(1)
    const [hoveredCode, setHoveredCode] = useState(null)
    const user = useSelector((state) => state.app.user)

    const { t } = useTranslation()
    const { codesListData } = useReturnCodeTypesData()
    const {
        setIsDynamic, isDynamic, setUserQrType,
        userQrType, editDesign, setEditDesign,
        resetDesignStyles, staticValue, isGenerating,
        createTempVersionOfDesign, applyTempDesignChanges,
    } = useUserCodeCreationContext()

    const handleClickCode = (name) => {
        setStep(2)
        setUserQrType(name)
    }

    const previewImage = useMemo(() => {
        switch (hoveredCode) {
            case 'URL':
            case 'Text':
            case 'Wi-Fi':
            case 'Image':
            case 'PDF':
            case 'Video':
            case 'MP3': {
                return <img
                    src={
                        USER_CREATE_CODE_IMAGES_TRANSLATIONS[i18n.language]?.[hoveredCode]
                        ?? USER_CREATE_CODE_IMAGES_TRANSLATIONS['en']?.[hoveredCode]
                    }
                    className='desktop-creation-preview-image'
                    alt=""
                />
            }
            default: {
                return <img
                    src={
                        USER_CREATE_CODE_IMAGES_TRANSLATIONS[i18n.language]?.["DEFAULT"]
                        ?? USER_CREATE_CODE_IMAGES_TRANSLATIONS['en']?.["DEFAULT"]
                    }
                    className='desktop-creation-preview-image'
                    alt=""
                />
            }
        }
    }, [hoveredCode])

    return (
        <div style={{ minHeight: `${user ? 'calc(100vh - 68px)' : 'calc(100vh - 60px)'}` }} className='desktop-creation-flow'>
            <div style={{ minHeight: `${user ? 'calc(100vh - 68px)' : 'calc(100vh - 60px)'}` }} className='desktop-creation-left-side justify-between'>
                {step === 1 ? (
                    <div className='flex flex-col gap-[24px]'>
                        <div className='desktop-creation-select-type-header'>
                            <h5 className='desktop-creation-select-type-header-title'>{t("selectCodeType")}</h5>
                            <div className='desktop-creation-dynamic-switch-block'>
                                <span className='desktop-creation-dynamic-switch-label'>{isDynamic ? t("dynamic") : t("static")}</span>
                                <div onClick={() => setIsDynamic(!isDynamic)} className={`overview-panel-stats-header-unique-scans-switch ${isDynamic ? 'active' : ''}`}>
                                    <div className={`overview-panel-switch-circle ${isDynamic ? 'active' : ''}`}></div>
                                </div>
                            </div>
                        </div>
                        <div className='desktop-creation-codes-list'>
                            {codesListData.map((el) => {
                                if (isDynamic) {
                                    return (
                                        <div
                                            onClick={() => handleClickCode(el.nameEnglish)}
                                            onMouseOver={() => setHoveredCode(el.nameEnglish)}
                                            onMouseLeave={() => setHoveredCode(null)}
                                            key={el.id}
                                            className='desktop-creation-code-item'
                                        >
                                            <img className='desktop-creation-code-icon' src={el.icon} alt="" />
                                            <div className='desktop-creation-code-info-block'>
                                                <p className='desktop-creation-code-name'>{el.name}</p>
                                                <p className='desktop-creation-code-description'>{el.text}</p>
                                            </div>
                                        </div>
                                    )
                                } else if (!isDynamic && el.isStatic) {
                                    return (
                                        <div
                                            key={el.id}
                                            className='desktop-creation-code-item'
                                            onClick={() => handleClickCode(el.nameEnglish)}
                                        >
                                            <img className='desktop-creation-code-icon' src={el.icon} alt="" />
                                            <div className='desktop-creation-code-info-block'>
                                                <p className='desktop-creation-code-name'>{el.name}</p>
                                                <p className='desktop-creation-code-description'>{el.text}</p>
                                            </div>
                                        </div>
                                    )
                                } else {
                                    return null
                                }
                            })}
                        </div>
                    </div>
                ) : (
                    <div
                        style={{
                            opacity: `${editDesign ? '0.3' : '1'}`,
                            pointerEvents: `${editDesign ? 'none' : 'unset'}`,
                            userSelect: `${editDesign ? 'none' : 'unset'}`
                        }}
                        className='flex flex-col w-full gap-6'
                    >
                        <h5 className='desktop-creation-step2-title'>
                            <span onClick={() => setStep(1)} className='desktop-creation-step2-title-back-text cursor-pointer'>{t("selectCodeType")}</span>
                            <IoIosArrowForward color={"#7D8898"} size={24} />
                            <span className='desktop-creation-step2-title-black'>{t(USER_QR_TYPE_TRANSLATIONS[userQrType])}</span>
                        </h5>
                        <DashboardCreationContent triggerValidation={triggerValidation} setIsValid={setIsValid} />
                    </div>
                )}
                {step === 2 && (
                    <div
                        style={{
                            opacity: `${editDesign ? '0.3' : '1'}`,
                            pointerEvents: `${editDesign ? 'none' : 'unset'}`,
                            userSelect: `${editDesign ? 'none' : 'unset'}`
                        }}
                        className='desktop-creation-generate-btn-wrapper'
                    >
                        <button
                            className='content-creation-actions-generate desktop-creation-generate-btn relative'
                            disabled={(staticValue && !isGenerating && (isDynamic ? isValid : true)) ? false : true}
                            onClick={handleTriggerAndGenerate}
                        >
                            {isGenerating && (
                                <svg style={{ position: 'absolute', left: 'calc(50% - 80px)' }} className='stripe-loader' width={25} height={25} fill="#9747FF" viewBox="25 25 50 50">
                                    <circle r="20" cy="50" cx="50"></circle>
                                </svg>
                            )}
                            {t("generate")}
                        </button>
                    </div>
                )}
            </div>
            <div style={{ minHeight: `${user ? 'calc(100vh - 68px)' : 'calc(100vh - 60px)'}` }} className={`desktop-creation-right-side ${step === 2 ? 'p-0' : ''}`}>
                {step === 1 ? (
                    <>
                        {isDynamic ? previewImage : (<img src={qrExampleSvg} alt="" />)}
                    </>
                ) : (
                    <>
                        {editDesign ? (
                            <div className='dashboard-content-container-right-side justify-start p-0 relative'>
                                <div className='desktop-creation-design-header'>
                                    <div
                                        onClick={() => { setEditDesign(false); applyTempDesignChanges() }}
                                        className='edit-design-acbk-block cursor-pointer desktop-creation-design-edit-back-btn'
                                    >
                                        <img src={CodeCreateBack} alt="" />
                                        <span className='edit-design-back-text'>{t("editDesign")}</span>
                                    </div>
                                    <span onClick={resetDesignStyles} className='edit-design-clear-btn'>{t("reset")}</span>
                                </div>
                                <div className='flex flex-col w-full p-6 pt-0 gap-3'>
                                    <EditDesignQrCode mobile={false} />
                                    {isDynamic && userQrType !== 'URL' && (
                                        <EditDesignLandingPage mobile={false} />
                                    )}
                                </div>
                                <div className='desktop-creation-apply-design-block'>
                                    <button onClick={() => setEditDesign(false)} className='desktop-creation-apply-button'>
                                        {t("apply")}
                                    </button>
                                </div>
                            </div>
                        ) : (
                            <div style={{ borderLeft: 'none', padding: '0', paddingBottom: '24px' }} className={`dashboard-content-container-right-side ${isDynamic ? '' : 'justify-between'}`}>
                                <div className='desktop-creation-design-header'>
                                    <span className='dashboard-content-creation-preview-title mb-0'>{t("preview")}</span>
                                    <img
                                        onClick={() => { setEditDesign(true); createTempVersionOfDesign() }}
                                        className='desktop-creation-design-edit-btn cursor-pointer'
                                        src={CodeCreateEdit}
                                        alt=""
                                    />
                                </div>
                                <DashboardPreviewBlock />
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    )
}

export default UserCreateCode