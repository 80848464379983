import React, { useCallback, useRef, useState } from 'react'

import { useCustomNavigate } from '../../hooks/useCustomNavigate'

import MobileCreationFlow from '../../components/MobileCreationFlow/MobileCreationFlow'
import UserCreateCode from '../../components/DashboardComponents/UserCreateCode'

const NewCreationDashboard = () => {
    const [isValid, setIsValid] = useState(true)

    const triggerValidationRef = useRef(null)

    const navigate = useCustomNavigate()

    const triggerValidation = useCallback((trigger) => {
        if (trigger) {
            triggerValidationRef.current = trigger
        }
    }, [])

    const handleTriggerAndGenerate = async () => {
        if (triggerValidationRef.current) {
            const result = await triggerValidationRef.current()

            if (result) {
                navigate('/sign-up?upgrade=true&download=true')
                window.gtag('event', 'land_on_sign_up_page', {
                    type: 'qr_code_creation',
                })
            }
        }
    }

    return (
        <div style={{ backgroundColor: 'white' }} className='user-dashboard-content-new-home'>
            <UserCreateCode
                handleTriggerAndGenerate={handleTriggerAndGenerate}
                triggerValidation={triggerValidation}
                setIsValid={setIsValid}
                isValid={isValid}
            />
            <MobileCreationFlow handleConvertData={() => { }} isNewHome={true} />
        </div>
    )
}

export default NewCreationDashboard